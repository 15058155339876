import { Collector } from "typings/Collector";
import { noop } from "utils/noop";

type Subject = {
  email?: string;
  id?: null | string;
};

const registerConsent = (
  subject: Subject,
  preferences: Record<string, boolean>,
  proofs: unknown,
) => {
  if (window?._iub) {
    window._iub.cons_instructions.push([
      "submit",
      {
        consent: {
          legal_notices: [
            {
              identifier: "privacy_policy",
            },
            {
              identifier: "cookie_policy",
            },
            {
              identifier: "terms",
            },
          ],
          preferences,
          proofs,
          subject,
        },
        writeOnLocalStorage: false,
      },
      {
        error() {
          noop();
        },
        success() {
          noop();
        },
      },
    ]);
  }
};

export const registerSignUpConsent = (user: Partial<Collector>) => {
  registerConsent(
    { email: user.email ?? "", id: user.id },
    {
      consent: true,
      signUp: true,
    },
    [
      {
        content: JSON.stringify({
          email: user.email,
          id: user.id,
          username: user.username,
        }),
      },
    ],
  );
};

export const registerNewsletterConsent = (
  id: null | string | undefined,
  email: string,
) => {
  registerConsent({ email, id }, { consent: true, newsletter: true }, [
    {
      content: JSON.stringify({
        email,
        id,
      }),
    },
  ]);
};
