import { memo, PropsWithChildren, ReactNode, Suspense } from "react";

import { DelayedFallback } from "./DelayedFallback";

export const SuspenseWrapper = memo(function SuspenseWrapper({
  children,
  fallback = <DelayedFallback />,
}: PropsWithChildren<{
  fallback?: ReactNode;
}>) {
  return <Suspense fallback={fallback}>{children}</Suspense>;
});
