import { lazy } from "react";
import { Navigate, Outlet, Route } from "react-router";

import { Layout } from "components/Layout/Layout";
import { SuspenseWrapper } from "components/Layout/SuspenseWrapper";
import {
  CONFIRM_EMAIL,
  CONFIRM_MFA,
  EARLY_ACCESS,
  LOGIN,
  NOT_FOUND,
  RESET_PASSWORD,
  SIGNUP,
  VIEW,
} from "constants/routes";
import { useLoggedInRootPath } from "hooks/useLoggedInRootPath";

const BIPage = lazy(() => import("pages/bi/BIPage"));
const ChangePasswordPage = lazy(
  () => import("pages/change-password/ChangePasswordPage"),
);
const ConfirmEmailPage = lazy(
  () => import("pages/confirm-email/ConfirmEmailPage"),
);
const ConfirmMfaPage = lazy(() => import("pages/confirm-mfa/ConfirmMfaPage"));
const KesselPage = lazy(() => import("pages/kessel/KesselPage"));
// const ListingPage = lazy(
//   () => import("pages/data-room/dataRoomId/PublicDataRoomPage"),
// );
const LoginPage = lazy(() => import("pages/login/LoginPage"));
const NotFoundPage = lazy(() => import("pages/not-found/NotFoundPage"));
const ResetPasswordPage = lazy(
  () => import("pages/reset-password/ResetPasswordPage"),
);
const SignUpPage = lazy(() => import("pages/signup/SignUpPage"));
const ViewPage = lazy(() => import("pages/view/ViewPage"));
const PublicAppBar = lazy(() => import("components/AppBar/PublicAppBar"));
const UnsubscribePage = lazy(() => import("pages/unsubscribe/UnsubscribePage"));

const ContainedLayout = () => (
  <Layout AppBar={PublicAppBar} onlyHorizontalAppBar>
    <SuspenseWrapper>
      <Outlet />
    </SuspenseWrapper>
  </Layout>
);

const NoAppBarLayout = () => (
  <Layout
    AppBar={undefined}
    sx={{ alignItems: "center", display: "flex", height: "100%", padding: 2 }}
  >
    <SuspenseWrapper>
      <Outlet />
    </SuspenseWrapper>
  </Layout>
);

const NarrowCenteredLayout = () => (
  <Layout
    maxWidth="xs"
    sx={{ alignItems: "center", display: "flex", height: "100%", padding: 2 }}
  >
    <SuspenseWrapper>
      <Outlet />
    </SuspenseWrapper>
  </Layout>
);

const Root = () => {
  const loggedInRootPath = useLoggedInRootPath();

  return <Navigate to={loggedInRootPath} />;
};

const routes = [
  <Route
    element={<Navigate to={SIGNUP} />}
    key={EARLY_ACCESS}
    path={EARLY_ACCESS}
  />,

  <Route element={<ContainedLayout />} key="contained">
    <Route element={<ViewPage />} key={VIEW} path="/view/:token" />
  </Route>,

  <Route element={<NarrowCenteredLayout />} key="contained">
    <Route key={SIGNUP} path={SIGNUP}>
      <Route element={<SignUpPage />} index />
    </Route>
    <Route
      element={<ResetPasswordPage />}
      key={RESET_PASSWORD}
      path={RESET_PASSWORD}
    />
    <Route
      element={<ChangePasswordPage />}
      key={`${RESET_PASSWORD}/:token`}
      path={`${RESET_PASSWORD}/:token`}
    />
    <Route
      element={<ConfirmEmailPage />}
      key={`${CONFIRM_EMAIL}/:token`}
      path={`${CONFIRM_EMAIL}/:token`}
    />
    <Route element={<LoginPage />} key={LOGIN} path={LOGIN} />,
    <Route element={<ConfirmMfaPage />} key={CONFIRM_MFA} path={CONFIRM_MFA} />,
    <Route element={<BIPage />} key="bi" path="/bi" />,
    <Route element={<KesselPage />} key="kessel" path="/kessel" />,
    <Route
      element={<UnsubscribePage />}
      key="unsubscribe"
      path="/unsubscribe"
    />
    <Route key="signUp" path="/signUp">
      <Route element={<SignUpPage />} index />
    </Route>
    <Route
      element={<ResetPasswordPage />}
      key="resetPassword"
      path="/resetPassword"
    />
    <Route
      element={<ChangePasswordPage />}
      key={`resetPassword/:token`}
      path={`resetPassword/:token`}
    />
    <Route
      element={<ConfirmEmailPage />}
      key={`confirmEmail/:token`}
      path={`confirmEmail/:token`}
    />
    <Route element={<ConfirmMfaPage />} key="confirmMfa" path="/confirmMfa" />
  </Route>,

  <Route element={<Root />} key="root" path="/" />,

  <Route element={<NoAppBarLayout />} key="not-found">
    <Route element={<NotFoundPage />} path="/*" />
    <Route element={<NotFoundPage />} path={`${NOT_FOUND}/*`} />
  </Route>,
];

export function PublicRoutes() {
  return <>{routes.map((route) => route)}</>;
}
