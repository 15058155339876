import Grid from "@mui/material/Grid2";
import { useTranslation } from "react-i18next";

import { TextFieldControlled } from "components/inputs/TextField/TextFieldControlled";

export const ServiceForm = () => {
  const { t } = useTranslation();

  return (
    <>
      <Grid size={{ xs: 12 }}>
        <TextFieldControlled
          data-testid="service-title-input"
          fullWidth
          label={t("title")}
          name="title"
          required
        />
      </Grid>

      <Grid size={{ xs: 12 }}>
        <TextFieldControlled
          clearable
          data-testid="service-description-input"
          fullWidth
          label={t("description")}
          minRows={2}
          multiline
          name="description"
        />
      </Grid>
    </>
  );
};
