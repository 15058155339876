import { AppTheme } from "typings/AppTheme";
import { Density, DensityScope, DensitySettings } from "typings/Density";
import {
  DashboardFilter,
  DashboardFilterSettings,
  ListingFilterSettings,
  LogbookFilterSettings,
  NegotiationsFilterSettings,
  RemindersFilterSettings,
  ServiceRequestsFilterSettings,
  UsageHistoryFilterSettings,
  VehicleFilterSettings,
} from "typings/Filter";
import { SortingScope, SortingSettings } from "typings/Sorting";
import { VehicleGridColumnsVisibilitySettings } from "typings/Visibility";

export const DEFAULT_DENSITY_SETTINGS: DensitySettings = {
  [DensityScope.COLLECTION]: Density.GRID,
  [DensityScope.LISTING]: Density.GRID,
  [DensityScope.LOCATION]: Density.GRID,
  [DensityScope.LOGBOOK]: Density.TIMELINE,
  [DensityScope.VEHICLE]: Density.GRID,
};

export const DEFAULT_APP_THEME: AppTheme = AppTheme.DRIVEN_DARK;

export const DEFAULT_SORTING_SETTINGS: SortingSettings = {
  [SortingScope.collection]: {
    sortBy: "titleAZ",
  },
  [SortingScope.collectors]: {
    sortBy: "registeredLatest",
  },
  [SortingScope.listing]: {
    sortBy: "listedNewest",
  },
  [SortingScope.negotiations]: {
    sortBy: "status",
  },
  [SortingScope.usageHistory]: {
    sortBy: "vehicleCountHighest",
  },
  [SortingScope.vehicle]: {
    sortBy: "addedNewest",
  },
};

export const DEFAULT_VEHICLE_FILTER_SETTINGS: VehicleFilterSettings = {
  statusFilter: [],
};

export const DEFAULT_LISTING_FILTER_SETTINGS: ListingFilterSettings = {
  fuzzySearchFilter: "",
  visibilityFilter: "active",
};

export const DEFAULT_NEGOTIATIONS_FILTER_SETTINGS: NegotiationsFilterSettings =
  {
    fuzzySearchFilter: "",
    roleFilter: null,
    statusFilter: "open",
  };

export const DEFAULT_LOGBOOK_FILTER_SETTINGS: LogbookFilterSettings = {
  highlightsOnly: false,
  typeFilter: null,
};

export const DEFAULT_REMINDERS_FILTER_SETTINGS: RemindersFilterSettings = {
  fuzzySearchFilter: "",
};

export const DEFAULT_VEHICLE_GRID_COLUMNS_VISIBILITY: VehicleGridColumnsVisibilitySettings =
  {
    actions: true,
    country: false,
    currentPrice: false,
    hp: false,
    id: false,
    image: true,
    make: true,
    model: true,
    odometer: false,
    status: false,
    vin: true,
    year: true,
  };

export const DEFAULT_SERVICE_REQUESTS_FILTER_SETTINGS: ServiceRequestsFilterSettings =
  {
    fuzzySearchFilter: "",
    statusFilter: "open",
  };

export const DEFAULT_DASHBOARD_FILTER: DashboardFilter = {
  collectionId: null,
  collectorId: null,
  locationId: null,
  tag: null,
  vehicleStatuses: null,
};

export const DEFAULT_DASHBOARD_FILTER_SETTINGS: DashboardFilterSettings = {};

export const DEFAULT_USAGE_HISTORY_FILTER_SETTINGS: UsageHistoryFilterSettings =
  {
    dateFilter: [new Date().getFullYear(), new Date().getMonth() + 1],
    fuzzySearchFilter: "",
    organizationFilter: null,
  };
