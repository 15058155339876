import useTheme from "@mui/material/styles/useTheme";
import { MuiOtpInput } from "mui-one-time-password-input";
import { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

const validateChar = (value: string) => !!value.match("[0-9]{1}");
import { useMessaging } from "contexts/Messaging/useMessagingContext";

export type OtpFormProps = {
  disabled?: boolean;
  error?: boolean;
  onSubmit: (code: string) => void;
};

export const OtpForm = ({ disabled, error, onSubmit }: OtpFormProps) => {
  const theme = useTheme();
  const { t } = useTranslation();

  const { enqueueMessage } = useMessaging();

  useEffect(() => {
    if (error) {
      enqueueMessage({
        text: t("mfaCodeError"),
        variant: "error",
      });
    }
  }, [error]);

  const [value, setValue] = useState<string>("");

  const handleChange = useCallback((newValue: string) => {
    setValue(newValue);
  }, []);

  const handleComplete = useCallback(
    (value: string) => {
      setValue("");
      onSubmit(value);
    },
    [onSubmit],
  );

  return (
    <MuiOtpInput
      autoFocus
      length={6}
      onChange={handleChange}
      onComplete={handleComplete}
      TextFieldsProps={{
        disabled,
        slotProps: {
          htmlInput: {
            "data-testid": "mfacode-input",
            maxLength: 1,
            style: {
              fontSize: theme.spacing(3),
              fontWeight: 600,
              padding: theme.spacing(1),
              textAlign: "center",
            },
          },
        },
      }}
      validateChar={validateChar}
      value={value}
    />
  );
};
