import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useTheme } from "@mui/material/styles";
import { memo } from "react";

import * as S from "./EmptyBlockIcon.styled";

type EmptyBlockIconProps = {
  icon: IconProp;
};

export const EmptyBlockIcon = memo(function EmptyBlockIcon({
  icon,
}: EmptyBlockIconProps) {
  const theme = useTheme();

  return (
    <S.IconWrapper>
      <FontAwesomeIcon
        color={theme.palette.action.disabled}
        icon={icon}
        size="2x"
      />
    </S.IconWrapper>
  );
});
