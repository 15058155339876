import {
  createContext,
  ReactNode,
  useCallback,
  useContext,
  useState,
} from "react";

import { ConfirmModal, ModalOptions } from "components/dialogs/ConfirmModal";
import { ConfirmedMfa } from "typings/Mfa";

export const ConfirmType = {
  CONFIRM: "confirm",
  DELETE: "delete",
  REMOVE: "remove",
  TRANSFER: "transfer",
  UNSAVED_CHANGES: "unsavedChanges",
} as const;

export type ConfirmType = (typeof ConfirmType)[keyof typeof ConfirmType];

const ConfirmContext = createContext<
  (options?: ModalOptions, type?: ConfirmType) => Promise<ConfirmedMfa | null>
>(() => Promise.resolve(null));

ConfirmContext.displayName = "Driven::Confirm";

export const useConfirm = () => useContext(ConfirmContext);

type ConfirmProviderProps = {
  children: ReactNode;
};

export const ConfirmProvider = ({ children }: ConfirmProviderProps) => {
  const [options, setOptions] = useState<ModalOptions>({});
  const [type, setType] = useState<ConfirmType>();
  const [resolve, setResolve] =
    useState<(confirmedMfa?: ConfirmedMfa) => void>();
  const [key, setKey] = useState(0);

  const confirm = useCallback(
    (options: ModalOptions = {}, type?: ConfirmType) => {
      return new Promise<ConfirmedMfa>((resolve) => {
        setKey((key) => key + 1);
        setOptions(options);
        setResolve(() => resolve);
        setType(type);
      });
    },
    [],
  );

  const handleClose = useCallback(() => {
    setResolve(undefined);
  }, []);

  const handleConfirm = useCallback(
    (confirmedMfa?: ConfirmedMfa) => {
      resolve?.(confirmedMfa);
      handleClose();
    },
    [resolve, handleClose],
  );

  return (
    <>
      <ConfirmContext.Provider value={confirm}>
        {children}
      </ConfirmContext.Provider>
      {!!resolve && (
        <ConfirmModal
          key={key}
          onClose={handleClose}
          onConfirm={handleConfirm}
          options={options}
          type={type}
        />
      )}
    </>
  );
};
