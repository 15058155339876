import { faClose } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import IconButton, { IconButtonProps } from "@mui/material/IconButton";
import { useTheme } from "@mui/material/styles";
import { memo } from "react";

type DialogCloseButtonProps = {
  onClose: () => void;
} & IconButtonProps;

export const DialogCloseButton = memo(function DialogCloseButton({
  onClose,
  sx,
  ...rest
}: DialogCloseButtonProps) {
  const theme = useTheme();

  return (
    <IconButton
      aria-label="close"
      data-testid="dialog-close-button"
      onClick={onClose}
      sx={{
        color: "primary.main",
        height: theme.spacing(4),
        width: theme.spacing(4),
        ...sx,
      }}
      {...rest}
    >
      <FontAwesomeIcon icon={faClose} size="xs" />
    </IconButton>
  );
});
