import { useEffect } from "react";
import { useResolve } from "react-jpex";
import { useSearchParams } from "react-router";

import type { UserPreferenceService } from "typings/UserPreferenceService";

export const useTracking = () => {
  const preferenceService = useResolve<UserPreferenceService>(
    "UserPreferenceService",
  );

  const [params] = useSearchParams();

  useEffect(() => {
    const localData = preferenceService.getMarketing();

    if (localData) return;

    const marketing: Record<string, string> = Object.fromEntries(
      params.entries(),
    );

    if (document.referrer) marketing.referrer = document.referrer;

    if (Object.keys(marketing).length) {
      preferenceService.setMarketing(marketing);
    }
  }, []);
};
