import { Outlet } from "react-router";

import { EncryptionDialogs } from "components/encryption/dialogs/EncryptionDialogs";
import { SuspenseWrapper } from "components/Layout/SuspenseWrapper";
import { SubscriptionDialogs } from "components/subscription/dialogs/SubscriptionDialogs";

export const SettingsDialogs = () => (
  <>
    <SuspenseWrapper>
      <Outlet />
    </SuspenseWrapper>

    <SuspenseWrapper>
      <EncryptionDialogs />
      <SubscriptionDialogs />
    </SuspenseWrapper>
  </>
);
