import useMediaQuery from "@mui/material/useMediaQuery";
import { createContext, ReactNode, useContext, useMemo, useState } from "react";
import { useResolve } from "react-jpex";

import { DEFAULT_APP_THEME } from "constants/settings";
import { THEME_MODE_CHANGED_EVENT, THEME_MODE_FIELD } from "constants/tracking";
import { AppTheme } from "typings/AppTheme";
import { UserPreferenceService } from "typings/UserPreferenceService";
import { noop } from "utils/noop";
import { sendToGTM } from "utils/sendToGTM";

type AppThemeContextType = {
  appTheme: AppTheme;
  isPartnerTheme: boolean;
  setTheme: (AppTheme: AppTheme) => void;
  toggleDrivenTheme: () => void;
};

export const defaultAppThemeContext: AppThemeContextType = {
  appTheme: DEFAULT_APP_THEME,
  isPartnerTheme: false,
  setTheme: () => noop,
  toggleDrivenTheme: noop,
};

const AppThemeContext = createContext(defaultAppThemeContext);

AppThemeContext.displayName = "Driven::AppTheme";

export const useAppTheme = () => useContext(AppThemeContext);

type AppThemeContextProviderProps = {
  children: ReactNode;
};
export const AppThemeContextProvider = ({
  children,
}: AppThemeContextProviderProps) => {
  const localPreferenceService = useResolve<UserPreferenceService>(
    "UserPreferenceService",
  );

  const prefersDarkMode = useMediaQuery("(prefers-color-scheme: dark)", {
    noSsr: true,
  });

  const localAppTheme = localPreferenceService.getAppTheme();

  const initialAppTheme =
    localAppTheme ||
    (prefersDarkMode ? AppTheme.DRIVEN_DARK : AppTheme.DRIVEN_LIGHT);

  const [appTheme, setAppTheme] = useState<AppTheme>(initialAppTheme);

  const value = useMemo(
    () => ({
      appTheme: appTheme ?? DEFAULT_APP_THEME,
      isPartnerTheme:
        appTheme !== AppTheme.DRIVEN_DARK && appTheme !== AppTheme.DRIVEN_LIGHT,
      setTheme: (appTheme: AppTheme) => {
        localPreferenceService.setAppTheme(appTheme);

        setAppTheme(appTheme);

        sendToGTM({
          event: THEME_MODE_CHANGED_EVENT,
          [THEME_MODE_FIELD]: appTheme,
        });
      },
      toggleDrivenTheme: () => {
        const oppositeAppTheme =
          appTheme === AppTheme.DRIVEN_LIGHT
            ? AppTheme.DRIVEN_DARK
            : AppTheme.DRIVEN_LIGHT;

        localPreferenceService.setAppTheme(oppositeAppTheme);

        setAppTheme(oppositeAppTheme);

        sendToGTM({
          event: THEME_MODE_CHANGED_EVENT,
          [THEME_MODE_FIELD]: oppositeAppTheme,
        });
      },
    }),
    [appTheme, setAppTheme],
  );

  return (
    <AppThemeContext.Provider value={value}>
      {children}
    </AppThemeContext.Provider>
  );
};
