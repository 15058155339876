import { useResolve } from "react-jpex";
import { To } from "react-router";

import {
  ADMIN_VEHICLES,
  DASHBOARD,
  LOGIN,
  ORGANIZATION,
} from "constants/routes";
import { AuthenticationService } from "typings/AuthenticationService";
import { hasOrganizationPrivileges, isAdmin, isCollector } from "typings/Role";

export const useLoggedInRootPath = (fromPathName?: string): To => {
  const authService = useResolve<AuthenticationService>(
    "AuthenticationService",
  );

  if (!authService.isAuthenticated()) {
    return LOGIN;
  }

  if (fromPathName) {
    return fromPathName;
  }

  const role = authService.getRole();

  let path = "/";

  if (isCollector(role)) {
    path = DASHBOARD;
  } else if (isAdmin(role)) {
    path = ADMIN_VEHICLES;
  } else if (hasOrganizationPrivileges(role)) {
    path = ORGANIZATION;
  }

  return path as To;
};
