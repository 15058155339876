import { lazy } from "react";

import { SuspenseWrapper } from "components/Layout/SuspenseWrapper";
import { useModalWithSubscription } from "hooks/useModalWithSubscription";
import { usePublishMessageOnNavigation } from "hooks/useNavigation";
import { Collector } from "typings/Collector";
import { CollectorTopics, DialogTopics } from "typings/Topics";

const EditCollectorModal = lazy(
  () => import("components/Collector/dialogs/EditCollectorModal"),
);
const AddCollectorModal = lazy(
  () => import("components/Collector/dialogs/AddCollectorModal"),
);

export const CollectorDialogs = () => {
  const { handleClose: handleCloseEdit, object: toEdit } =
    useModalWithSubscription<Collector>(
      DialogTopics.EditCollector,
      CollectorTopics.Updated,
    );

  const { handleClose: handleCloseAdd, object: toAdd } =
    useModalWithSubscription(DialogTopics.AddCollector, CollectorTopics.Added);

  usePublishMessageOnNavigation();

  return (
    <SuspenseWrapper>
      {!!toAdd && <AddCollectorModal onClose={handleCloseAdd} />}

      {!!toEdit && (
        <EditCollectorModal collector={toEdit} onClose={handleCloseEdit} />
      )}
    </SuspenseWrapper>
  );
};
