import i18n from "i18n/config";

export const Role = {
  ADMIN: "admin",
  COLLECTOR: "collector",
  CONCIERGE: "concierge",
  DEALER: "dealer",
  DEALERSHIP: "dealership",
  ORGANIZATION_MANAGER: "organization_manager",
} as const;

export type Role = (typeof Role)[keyof typeof Role];

export const translatedRoles: Record<Role, string> = {
  [Role.ADMIN]: i18n.t("role.admin"),
  [Role.COLLECTOR]: i18n.t("role.collector"),
  [Role.CONCIERGE]: i18n.t("role.concierge"),
  [Role.DEALER]: i18n.t("role.dealer"),
  [Role.DEALERSHIP]: i18n.t("role.dealership"),
  [Role.ORGANIZATION_MANAGER]: i18n.t("role.organization_manager"),
};

export const validUserRoles: Role[] = Object.values(Role);

export const isAdmin = (role?: Role) => !!role && role === Role.ADMIN;

export const isCollector = (role?: Role) => !!role && role === Role.COLLECTOR;

export const isConcierge = (role?: Role) => !!role && role === Role.CONCIERGE;

export const isDealer = (role?: Role) => !!role && role === Role.DEALER;

export const isDealership = (role?: Role) => !!role && role === Role.DEALERSHIP;

export const isOrganizationManager = (role?: Role) =>
  !!role && role === Role.ORGANIZATION_MANAGER;

export const hasOrganizationPrivileges = (role?: Role) =>
  isConcierge(role) || isOrganizationManager(role) || isDealer(role);
