import { gql, Reference, useMutation } from "@apollo/client";
import { useCallback } from "react";
import { useTranslation } from "react-i18next";
import { useResolve } from "react-jpex";

import { MEMBERSHIP_FRAGMENT } from "constants/fragments";
import { useMessaging } from "contexts/Messaging/useMessagingContext";
import { Collector } from "typings/Collector";
import { CrewMembership } from "typings/CrewMembership";
import { UserPreferenceService } from "typings/UserPreferenceService";

const CONFIRM_CREW_MEMBERSHIP = gql`
  ${MEMBERSHIP_FRAGMENT}
  mutation ConfirmCrewMembership($token: String!) {
    confirmCrewMembership(token: $token) {
      ...MembershipFragment
      token
    }
  }
`;

export const useConfirmCrewMembership = () => {
  const userPreferenceService = useResolve<UserPreferenceService>(
    "UserPreferenceService",
  );

  const { t } = useTranslation();
  const { enqueueMessage } = useMessaging();

  const [mutate, { client }] = useMutation<{
    confirmCrewMembership: CrewMembership;
  }>(CONFIRM_CREW_MEMBERSHIP);

  const confirmCrewMembership = useCallback(
    async (token?: null | string) => {
      if (!token) return;

      const { data, errors } = await mutate({ variables: { token } });

      userPreferenceService.setCrewInviteCode(undefined);

      if (errors?.[0]) {
        enqueueMessage({
          persist: true,
          text: t(errors[0].message),
          variant: "error",
        });
      }

      if (data?.confirmCrewMembership) {
        client.cache.modify<Collector>({
          fields: {
            memberOfCrews: (existing, { toReference }) => [
              toReference(data.confirmCrewMembership) as Reference,
              ...(existing as Reference[]),
            ],
          },
          id: `Collector:${data.confirmCrewMembership.member.id}`,
        });

        enqueueMessage({
          text: t("user.crew.invite.confirmed", {
            username: data.confirmCrewMembership.manager.username,
          }),
          variant: "success",
        });
      }
    },
    [mutate, enqueueMessage, t],
  );

  return { confirmCrewMembership };
};
