import { ApolloClient } from "@apollo/client";
import { jpex } from "jpex";

import type { AdminCollectorService } from "typings/AdminCollectorService";
import type { AdminService } from "typings/AdminService";
import type { AuthenticationService } from "typings/AuthenticationService";
import type { CollectionService } from "typings/CollectionService";
import type { CollectorService } from "typings/CollectorService";
import type { ListViewService } from "typings/ListViewService";
import type { LocationService } from "typings/LocationService";
import type { MediaService } from "typings/MediaService";
import type { UserPreferenceService } from "typings/UserPreferenceService";
import type { VehicleService } from "typings/VehicleService";

import ApolloAdminCollectorService from "services/ApolloAdminCollectorService";
import ApolloAdminService from "services/ApolloAdminService";
import { ApolloClientProvider } from "services/ApolloClientProvider";
import ApolloCollectionService from "services/ApolloCollectionService";
import ApolloCollectorService from "services/ApolloCollectorService";
import ApolloListViewService from "services/ApolloListViewService";
import ApolloLocationService from "services/ApolloLocationService";
import ApolloMediaService from "services/ApolloMediaService";
import ApolloVehicleService from "services/ApolloVehicleService";
import LocalStorageAuthenticationService from "services/LocalStorageAuthenticationService";
import StorageUserPreferenceService from "services/StorageUserPreferenceService";
import { noop } from "utils/noop";

function getAuthenticationService() {
  return new LocalStorageAuthenticationService();
}

function getUserPreferenceService() {
  return new StorageUserPreferenceService(window.localStorage);
}

let apolloClient: ApolloClient<any>;

function createApolloClient() {
  return new ApolloClientProvider(getAuthenticationService()).getApolloClient();
}

function getAdminCollectorService() {
  return new ApolloAdminCollectorService(getApolloClient());
}

function getAdminService() {
  return new ApolloAdminService(getApolloClient());
}

function getApolloClient() {
  if (!apolloClient) {
    apolloClient = createApolloClient();
  }

  return apolloClient;
}

function getCollectionService() {
  return new ApolloCollectionService(getApolloClient());
}

function getCollectorService() {
  return new ApolloCollectorService(getApolloClient());
}

function getListViewService() {
  return new ApolloListViewService(getApolloClient());
}

function getLocationService() {
  return new ApolloLocationService(getApolloClient());
}

function getMediaService() {
  return new ApolloMediaService(getApolloClient());
}

function getVehicleService() {
  return new ApolloVehicleService(getApolloClient());
}

jpex.factory<AdminCollectorService>("AdminCollectorService", [], () =>
  getAdminCollectorService(),
);

jpex.factory<AuthenticationService>("AuthenticationService", [], () =>
  getAuthenticationService(),
);

jpex.factory<VehicleService>("VehicleService", [], () => getVehicleService());

jpex.factory<LocationService>("LocationService", [], () =>
  getLocationService(),
);

jpex.factory<CollectionService>("CollectionService", [], () =>
  getCollectionService(),
);

jpex.factory<CollectorService>("CollectorService", [], () =>
  getCollectorService(),
);

jpex.factory<AdminService>("AdminService", [], () => getAdminService());

jpex.factory<MediaService>("MediaService", [], () => getMediaService());

jpex.factory<UserPreferenceService>("UserPreferenceService", [], () =>
  getUserPreferenceService(),
);

jpex.factory<ListViewService>("ListViewService", [], () =>
  getListViewService(),
);

function init() {
  noop();
}

const ServiceProvider = {
  createApolloClient,
  getApolloClient,
  init,
};

export default ServiceProvider;
