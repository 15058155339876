import { useEffect } from "react";
import { useResolve } from "react-jpex";

import { AuthenticationService } from "typings/AuthenticationService";
import { Collector } from "typings/Collector";
import { UserTopics } from "typings/Topics";

/** When tier information for collector is out of sync with token,
 * force a token refresh to update tier level in access token
 * */
export const useForceRefreshTokenOnSubscriptionChanges = (
  collector: Collector | undefined,
) => {
  const authenticationService = useResolve<AuthenticationService>(
    "AuthenticationService",
  );

  useEffect(() => {
    const tierLevel = authenticationService.getTierLevel();
    const tierExpirationDate = authenticationService.getTierExpirationDate();

    const outOfSync =
      tierExpirationDate !== collector?.tierExpirationDate ||
      tierLevel !== collector?.tierLevel;

    if (collector?.id && outOfSync) {
      // this simulates a token expired error, and will force a refresh
      PubSub.publish(UserTopics.TokenExpired);
    }
  }, [collector?.id, collector?.tierExpirationDate, collector?.tierLevel]);
};
