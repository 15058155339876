import i18n from "i18n/config";

export const TextDetectionStatus = {
  PARSING_FAILED: "PARSING_FAILED",
  PARSING_SUCCEEDED: "PARSING_SUCCEEDED",
  TEXT_DETECTION_FAILED: "TEXT_DETECTION_FAILED",
  TEXT_DETECTION_FINISHED: "TEXT_DETECTION_FINISHED",
  TEXT_DETECTION_STARTED: "TEXT_DETECTION_STARTED",
  TEXT_DETECTION_SUCCEEDED: "TEXT_DETECTION_SUCCEEDED",
} as const;

export type TextDetection = {
  id: string;
  message?: string;
  status: TextDetectionStatus;
};

export type TextDetectionStatus =
  (typeof TextDetectionStatus)[keyof typeof TextDetectionStatus];

export const translatedTextDetectionStatus: Record<
  TextDetectionStatus,
  string
> = {
  [TextDetectionStatus.PARSING_FAILED]: i18n.t(
    "textDetection.status.parsing.failed",
  ),
  [TextDetectionStatus.PARSING_SUCCEEDED]: i18n.t(
    "textDetection.status.parsing.succeeded",
  ),
  [TextDetectionStatus.TEXT_DETECTION_FAILED]: i18n.t(
    "textDetection.status.failed",
  ),
  [TextDetectionStatus.TEXT_DETECTION_FINISHED]: i18n.t(
    "textDetection.status.finished",
  ),
  [TextDetectionStatus.TEXT_DETECTION_STARTED]: i18n.t(
    "textDetection.status.started",
  ),
  [TextDetectionStatus.TEXT_DETECTION_SUCCEEDED]: i18n.t(
    "textDetection.status.succeeded",
  ),
};

const translatedErrorMessages = {
  INVALID_IMAGE_TYPE: i18n.t("textDetection.error.invalidImageType"),
  PASSWORD_PROTECTED_DOCUMENT: i18n.t(
    "textDetection.error.passwordProtectedDocument",
  ),
};

export const getTranslatedErrorMessage = (message?: string) => {
  if (!message) return;

  if (message.startsWith("error.")) {
    return i18n.t(message);
  }

  const translated =
    translatedErrorMessages[message as keyof typeof translatedErrorMessages];

  return translated || message;
};
