import i18n from "i18next";
import { initReactI18next } from "react-i18next";

import { DEFAULT_LANGUAGE, Language, validLanguages } from "typings/Language";

import de from "./locales/de.json";
import en from "./locales/en.json";

i18n.use(initReactI18next).init({
  debug: process.env.NODE_ENV === "development",
  defaultNS: "translations",
  fallbackLng: DEFAULT_LANGUAGE,
  interpolation: {
    escapeValue: false,
  },
  ns: ["translations"],
  resources: {
    [Language.DE]: { translations: de },
    [Language.EN]: { translations: en },
  },
});

i18n.languages = validLanguages;

export default i18n;
