import { lazy } from "react";

import { useModalWithSubscription } from "hooks/useModalWithSubscription";
import { usePublishMessageOnNavigation } from "hooks/useNavigation";
import { CollectorTopics, UserTopics } from "typings/Topics";
const OverQuotaModal = lazy(
  () => import("components/subscription/dialogs/OverQuotaModal"),
);

export const SubscriptionDialogs = () => {
  const { handleClose: handleCloseOverQuota, object: overQuota } =
    useModalWithSubscription(
      UserTopics.OverQuota,
      CollectorTopics.TierSelected,
    );

  usePublishMessageOnNavigation();

  return (
    <>{!!overQuota && <OverQuotaModal onClose={handleCloseOverQuota} />}</>
  );
};
