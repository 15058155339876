import { memo, useEffect, useState } from "react";

type DelayedWrapperProps = {
  children?: React.ReactNode;
  delay?: number;
};

export const DelayedWrapper = memo(function DelayedWrapper({
  children,
  delay = 300,
}: DelayedWrapperProps) {
  const [show, setShow] = useState(false);
  useEffect(() => {
    const timeout = setTimeout(() => setShow(true), delay);
    return () => {
      clearTimeout(timeout);
    };
  }, [delay]);

  return show ? <> {children} </> : null;
});
