import { lazy } from "react";

import { SuspenseWrapper } from "components/Layout/SuspenseWrapper";
import { VaultRecoverPayload } from "components/Vault/dialogs/RecoverMediaDialog";
import { useModalWithSubscription } from "hooks/useModalWithSubscription";
import { Media } from "typings/Media";
import { DialogTopics, VehicleTopics } from "typings/Topics";

const EditMediaDialog = lazy(() => import("components/Vault/EditMediaDialog"));
const AssignToHistoryRecordDialog = lazy(
  () => import("components/Vault/AssignToHistoryRecordDialog"),
);
const AssignToVehicleDialog = lazy(
  () => import("components/Vault/AssignToVehicleDialog"),
);
const RecoverMediaDialog = lazy(
  () => import("components/Vault/dialogs/RecoverMediaDialog"),
);

export const VaultDialogs = () => {
  const { handleClose: handleCloseEdit, object: toEdit } =
    useModalWithSubscription<Media>(
      DialogTopics.VaultEditMedia,
      VehicleTopics.MediaUpdated,
    );

  const {
    handleClose: handleCloseAssignToHistoryRecord,
    object: toAssignToHistoryRecord,
  } = useModalWithSubscription<Media>(
    DialogTopics.VaultAssignToHistoryRecord,
    VehicleTopics.MediaUpdated,
  );

  const { handleClose: handleCloseAssignVehicle, object: toAssignVehicle } =
    useModalWithSubscription<Media>(
      DialogTopics.VaultAssignToVehicle,
      VehicleTopics.MediaUpdated,
    );

  const { handleClose: handleCloseRecoverMedia, object: toRecoverMedia } =
    useModalWithSubscription<VaultRecoverPayload>(
      DialogTopics.VaultRecoverMedia,
    );

  return (
    <SuspenseWrapper data-testid="vault-dialogs">
      {!!toEdit && <EditMediaDialog media={toEdit} onClose={handleCloseEdit} />}

      {!!toAssignToHistoryRecord && (
        <AssignToHistoryRecordDialog
          media={toAssignToHistoryRecord}
          onClose={handleCloseAssignToHistoryRecord}
        />
      )}

      {!!toAssignVehicle && (
        <AssignToVehicleDialog
          media={toAssignVehicle}
          onClose={handleCloseAssignVehicle}
        />
      )}

      {!!toRecoverMedia && (
        <RecoverMediaDialog
          {...toRecoverMedia}
          onClose={handleCloseRecoverMedia}
        />
      )}
    </SuspenseWrapper>
  );
};
