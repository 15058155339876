import { memo } from "react";

import { DelayedWrapper } from "components/Layout/DelayedWrapper";
import { LoadingSpinner } from "components/Layout/LoadingSpinner";

export const DelayedFallback = memo(function DelayedFallback({
  delay = 300,
}: {
  delay?: number;
}) {
  return (
    <DelayedWrapper delay={delay}>
      <LoadingSpinner />
    </DelayedWrapper>
  );
});
