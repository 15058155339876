import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import { useTheme } from "@mui/material/styles";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import useMediaQuery from "@mui/material/useMediaQuery";
import { t } from "i18next";
import { memo, ReactNode, useCallback, useEffect, useState } from "react";
import { Trans, useTranslation } from "react-i18next";

import { ActionBoxResponsive } from "components/ActionBoxResponsive/ActionBoxResponsive";
import { ConfirmMfaForm } from "components/forms/ConfirmMfaForm";
import { CONFIRM_FORM } from "constants/forms";
import { ConfirmType } from "contexts/useConfirmContext";
import { ConfirmedMfa } from "typings/Mfa";
import { UserTopics } from "typings/Topics";

import { DialogHeader } from "./DialogHeader";

const DEFAULT_OPTIONS: ModalOptions = {
  cancelButtonText: t("dialog.confirm.default.button.cancel"),
  confirmButtonText: t("dialog.confirm.default.button.confirm"),
  title: t("dialog.confirm.default.title"),
};

const getUnsavedChangesDefaultOptions = (
  options?: ModalOptions,
): ModalOptions => ({
  ...DEFAULT_OPTIONS,
  confirmButtonText: t("dialog.confirm.unsavedChanges.button.confirm"),
  content: t("dialog.confirm.unsavedChanges.content"),
  title: t("dialog.confirm.unsavedChanges.title"),
  ...options,
});

const getRemoveDefaultOptions = (options?: ModalOptions): ModalOptions => ({
  ...DEFAULT_OPTIONS,
  confirmButtonText: t("dialog.confirm.remove.button.confirm"),
  content: options?.entityTitle ? (
    <Trans
      i18nKey="dialog.confirm.remove.content_entity"
      values={{ title: options.entityTitle }}
    >
      You are about to remove <strong>title</strong>, are you sure about it?
    </Trans>
  ) : (
    t("dialog.confirm.remove.content")
  ),
  title: t("dialog.confirm.remove.title"),
  ...options,
});

const getDeleteDefaultOptions = (options?: ModalOptions): ModalOptions => ({
  ...DEFAULT_OPTIONS,
  confirmButtonText: t("dialog.confirm.delete.button.confirm"),
  content: options?.entityTitle ? (
    <Trans
      i18nKey="dialog.confirm.delete.content_entity"
      values={{ title: options.entityTitle }}
    >
      You are about to delete <strong>title</strong>, are you sure about it?
    </Trans>
  ) : (
    t("dialog.confirm.delete.content")
  ),
  title: t("dialog.confirm.delete.title"),
  ...options,
});

const getTransferDefaultOptions = (options?: ModalOptions): ModalOptions => ({
  ...DEFAULT_OPTIONS,
  confirmButtonText: t("dialog.confirm.transfer.button.confirm"),
  content: options?.entityTitle ? (
    <Trans
      i18nKey="dialog.confirm.transfer.content_entity"
      values={{ title: options.entityTitle }}
    >
      You are about to transfer <strong>title</strong>, are you sure about it?
    </Trans>
  ) : (
    t("dialog.confirm.transfer.content")
  ),
  title: t("dialog.confirm.transfer.title"),
  ...options,
});

const getDefaultOptionsByType = (
  type?: ConfirmType,
  options?: ModalOptions,
) => {
  switch (type) {
    case ConfirmType.DELETE:
      return getDeleteDefaultOptions(options);
    case ConfirmType.REMOVE:
      return getRemoveDefaultOptions(options);
    case ConfirmType.TRANSFER:
      return getTransferDefaultOptions(options);
    case ConfirmType.UNSAVED_CHANGES:
      return getUnsavedChangesDefaultOptions();

    default:
      return DEFAULT_OPTIONS;
  }
};

const getOptions = (type?: ConfirmType, options?: ModalOptions) => {
  const defaultOptions = getDefaultOptionsByType(type, options);

  return {
    ...defaultOptions,
    ...options,
  };
};

export type ModalOptions = {
  cancelButtonText?: string;
  confirmationKeyword?: string;
  confirmButtonText?: string;
  content?: ReactNode;
  entityTitle?: string;
  isMfaRequired?: boolean;
  showIrreversibleWarning?: boolean;
  title?: string;
};

type ConfirmModalProps = {
  onClose: () => void;
  onConfirm: (confirmedMfa?: ConfirmedMfa) => void;
  options?: ModalOptions;
  type?: ConfirmType;
};

export const ConfirmModal = memo(function ConfirmModal({
  onClose,
  onConfirm,
  options = {},
  type,
}: ConfirmModalProps) {
  const { t } = useTranslation();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const {
    cancelButtonText,
    confirmationKeyword,
    confirmButtonText,
    content,
    isMfaRequired = false,
    showIrreversibleWarning,
    title,
  } = getOptions(type, options);

  const [value, setValue] = useState("");

  const [confirmButtonEnabled, setConfirmButtonEnabled] =
    useState(!confirmationKeyword);

  const [confirmedMfa, setConfirmedMfa] = useState<ConfirmedMfa>();

  const handleChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      setValue(event.target.value);
      setConfirmButtonEnabled(event.target.value === confirmationKeyword);
    },
    [confirmationKeyword],
  );

  const handleClose = useCallback(() => {
    setValue("");
    onClose();
  }, [onClose]);

  const handleConfirm = useCallback(() => {
    setValue("");
    onConfirm(confirmedMfa);
  }, [onConfirm, confirmedMfa]);

  useEffect(() => {
    const confirmMfa = PubSub.subscribe(
      UserTopics.MfaConfirmed,
      (_msg, data: ConfirmedMfa) => {
        setConfirmedMfa(data);
      },
    );

    return () => {
      PubSub.unsubscribe(confirmMfa);
    };
  }, []);

  const destructible = new Set<ConfirmType>([
    ConfirmType.DELETE,
    ConfirmType.REMOVE,
    ConfirmType.TRANSFER,
  ]);

  const isDestructible = type && destructible.has(type);

  return (
    <Dialog
      disableRestoreFocus
      fullScreen={isMobile}
      fullWidth
      maxWidth="sm"
      onClose={handleClose}
      open
    >
      <form
        id={CONFIRM_FORM}
        onSubmit={(event) => {
          event.preventDefault();
          handleConfirm();
        }}
      >
        <DialogHeader onClose={handleClose} title={title} />

        {isMfaRequired && !confirmedMfa?.method ? (
          <DialogContent
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: theme.spacing(2),
              justifyContent: "center",
            }}
          >
            <Box>{t("dialog.confirm.default.2fa")}</Box>
            <ConfirmMfaForm />
          </DialogContent>
        ) : (
          <>
            <DialogContent
              sx={{
                display: "flex",
                flexDirection: "column",
                gap: theme.spacing(2),
                justifyContent: "center",
              }}
            >
              <Box>{content}</Box>

              {!!confirmationKeyword && (
                <>
                  <Box>
                    <Trans
                      i18nKey="dialog.confirm.default.confirmationKeywordInstructions"
                      values={{
                        keyword: confirmationKeyword,
                      }}
                    >
                      Please type <strong>‘keyword’</strong> below to confirm:
                    </Trans>
                  </Box>
                  <TextField
                    autoFocus
                    fullWidth
                    inputRef={(input) => input && input.focus()}
                    onChange={handleChange}
                    placeholder={confirmationKeyword}
                    required
                    slotProps={{
                      htmlInput: {
                        "data-testid": "confirm-text-field",
                      },
                    }}
                    value={value}
                  />
                </>
              )}

              {showIrreversibleWarning && (
                <Typography color={theme.palette.error.main}>
                  {t("dialog.confirm.default.irreversibleWarning")}
                </Typography>
              )}
            </DialogContent>
            <DialogActions sx={{ backgroundColor: "background.default" }}>
              <ActionBoxResponsive>
                {!isDestructible && (
                  <Button
                    autoFocus={!confirmationKeyword}
                    color="primary"
                    data-testid={"confirm-button"}
                    disabled={!confirmButtonEnabled}
                    size="medium"
                    type="submit"
                    variant="contained"
                  >
                    {confirmButtonText}
                  </Button>
                )}

                <Button
                  color={isDestructible ? "primary" : "secondary"}
                  data-testid={"cancel-button"}
                  onClick={handleClose}
                  size="medium"
                  variant={isDestructible ? "contained" : "text"}
                >
                  {cancelButtonText}
                </Button>

                {isDestructible && (
                  <Button
                    autoFocus={!confirmationKeyword}
                    color="error"
                    data-testid={"confirm-button"}
                    disabled={!confirmButtonEnabled}
                    size="medium"
                    type="submit"
                    variant="outlined"
                  >
                    {confirmButtonText}
                  </Button>
                )}
              </ActionBoxResponsive>
            </DialogActions>
          </>
        )}
      </form>
    </Dialog>
  );
});
