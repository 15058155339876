import { init, reactRouterV7BrowserTracingIntegration } from "@sentry/react";
import { withoutGraphQLFetch } from "apollo-link-sentry";
import { useEffect } from "react";
import {
  createRoutesFromChildren,
  matchRoutes,
  useLocation,
  useNavigationType,
} from "react-router";

const ignoreTransactions = [
  "version.txt",
  "hermes.driven.world",
  "mouseflow.com",
  "iubenda.com",
  "taboola.com",
];

const shouldCreateSpanForRequest = (url: string) => {
  const isIgnored = ignoreTransactions.some((i) => url.indexOf(i) !== -1);

  return !isIgnored;
};

const tracesSampleRateByEnv: Record<
  ImportMetaEnv["REACT_APP_SENTRY_ENVIRONMENT"],
  number
> = {
  development: 1,
  production: 0.5,
  staging: 1,
  test: 0,
};

const tracesSampleRate =
  tracesSampleRateByEnv[import.meta.env.REACT_APP_SENTRY_ENVIRONMENT];

const sampleRateByEnv: Record<
  ImportMetaEnv["REACT_APP_SENTRY_ENVIRONMENT"],
  number
> = {
  development: 1,
  production: 1,
  staging: 1,
  test: 0,
};

const sampleRate =
  sampleRateByEnv[import.meta.env.REACT_APP_SENTRY_ENVIRONMENT];

export function initSentry() {
  init({
    beforeBreadcrumb: withoutGraphQLFetch((breadcrumb) => {
      if (breadcrumb.category === "console" && breadcrumb.level === "warning") {
        return null;
      } else {
        return breadcrumb;
      }
    }),
    denyUrls: [
      // Chrome extensions
      /extensions\//i,
      /^chrome:\/\//i,
      /^chrome-extension:\/\//i,
      // Other plugins
      /metrics\.itunes\.apple\.com\.edgesuite\.net\//i,
    ],
    dsn: "https://0b1131dc4f9f43d4902c42296e946193@o999407.ingest.sentry.io/5958343",
    environment: import.meta.env.REACT_APP_SENTRY_ENVIRONMENT,
    ignoreErrors: [
      // Random plugins/extensions
      "top.GLOBALS",
    ],
    ignoreTransactions,
    integrations: [
      reactRouterV7BrowserTracingIntegration({
        createRoutesFromChildren,
        matchRoutes,
        shouldCreateSpanForRequest,
        traceXHR: false,
        useEffect: useEffect,
        useLocation,
        useNavigationType,
      }),
    ],
    release: import.meta.env.REACT_APP_VERSION,
    sampleRate,
    tracePropagationTargets: [
      "localhost:3000",
      /^\//,
      /api\.driven\.world\//,
      /api\.staging\.driven\.world\//,
    ],
    tracesSampleRate,
  });
}
