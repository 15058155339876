import { ApolloClient, gql, Reference } from "@apollo/client";

import { LIST_VIEW_FIELDS } from "constants/fragments";
import { ListView } from "typings/ListView";
import { ListViewService } from "typings/ListViewService";

const FETCH_LIST_VIEWS = gql`
  ${LIST_VIEW_FIELDS}
  query FetchListViews {
    listViews {
      ...ListViewFields
    }
  }
`;

const DELETE_LIST_VIEW = gql`
  mutation DeleteListView($id: ID!) {
    deleteListView(id: $id) {
      void
    }
  }
`;

const SAVE_LIST_VIEW = gql`
  ${LIST_VIEW_FIELDS}
  mutation SaveListView($id: ID, $title: String!, $columns: [String!]) {
    saveListView(listViewData: { id: $id, title: $title, columns: $columns }) {
      ...ListViewFields
    }
  }
`;

export type PersistedListView = {
  columns: string[];
} & Omit<Partial<ListView>, "columns">;

export default class ApolloListViewService implements ListViewService {
  constructor(private apolloClient: ApolloClient<any>) {}

  public async delete(id: ListView["id"]): Promise<void> {
    const { errors } = await this.apolloClient.mutate({
      mutation: DELETE_LIST_VIEW,
      variables: { id },
    });

    if (errors) {
      throw errors[0];
    }

    this.apolloClient.cache.evict({ id: `ListView:${id}` });
  }

  public async fetchAll(): Promise<ListView[]> {
    const { data, errors } = await this.apolloClient.query<{
      listViews: ListView[];
    }>({
      fetchPolicy: "cache-first",
      query: FETCH_LIST_VIEWS,
    });

    if (errors) {
      throw errors[0];
    }

    return data.listViews;
  }

  public async save(
    listView: Partial<ListView>,
  ): Promise<ListView | undefined> {
    const stringifiedColumnsListView: PersistedListView = {
      ...listView,
      columns: listView.columns?.map((column) => JSON.stringify(column)) ?? [],
    };

    const { data, errors } = await this.apolloClient.mutate<{
      saveListView: ListView;
    }>({
      mutation: SAVE_LIST_VIEW,
      variables: stringifiedColumnsListView,
    });

    if (errors) {
      throw errors[0];
    }

    // only update the cache if we're creating a new list view
    if (data?.saveListView && !stringifiedColumnsListView?.id) {
      this.apolloClient.cache.modify<{ listViews: Reference[] }>({
        fields: {
          listViews: (existingListViews, { toReference }) => [
            toReference(data?.saveListView) as Reference,
            ...existingListViews,
          ],
        },
      });
    }

    return data?.saveListView;
  }
}
