import { useApolloClient } from "@apollo/client";
import { useCallback, useEffect, useState } from "react";
import { useResolve } from "react-jpex";

import { AuthenticationService } from "typings/AuthenticationService";
import { GuardModes } from "typings/GuardModes";
import { UserTopics } from "typings/Topics";
import { reload } from "utils/window";

export const useGuardModal = () => {
  const authenticationService = useResolve<AuthenticationService>(
    "AuthenticationService",
  );

  const apolloClient = useApolloClient();

  const [mode, setMode] = useState<GuardModes | null>(null);
  const [redirectCallback, setRedirectCallback] = useState<(() => void) | null>(
    null,
  );

  const handleClose = useCallback(() => {
    setMode(null);
    setRedirectCallback(null);
  }, []);

  useEffect(() => {
    const loginRequired = PubSub.subscribe(
      UserTopics.LoginRequired,
      (_msg, data) => {
        setMode("login");
        setRedirectCallback(
          data?.redirectCallback ? () => data.redirectCallback : null,
        );
      },
    );

    const signUpRequired = PubSub.subscribe(UserTopics.SignUpRequired, () => {
      setMode("signUp");
    });

    const unauthorized = PubSub.subscribe(UserTopics.Unauthorized, async () => {
      authenticationService.clearTokens();

      await apolloClient.clearStore();

      PubSub.publish(UserTopics.LoggedOut);

      reload();
    });

    return () => {
      PubSub.unsubscribe(unauthorized);
      PubSub.unsubscribe(loginRequired);
      PubSub.unsubscribe(signUpRequired);
    };
  }, []);

  return { handleClose, mode, redirectCallback };
};
