export const AppTheme = {
  ACME: "acme",
  ASCARI_LIGHT: "ascari",
  BI_DARK: "bi",
  CURATED_LIGHT: "curated",
  DRIVEN_DARK: "dark",
  DRIVEN_LIGHT: "light",
  ILR_DARK: "ilr",
  KESSEL_DARK: "kessel",
} as const;

export type AppTheme = (typeof AppTheme)[keyof typeof AppTheme];

export const MuiMode = {
  DARK: "dark",
  LIGHT: "light",
} as const;

export const validAppThemes: AppTheme[] = Object.values(AppTheme);

export type MuiMode = (typeof MuiMode)[keyof typeof MuiMode];
