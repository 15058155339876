import { ApolloClient, gql } from "@apollo/client";
import { Buffer } from "buffer";

import { LOCATION_FIELDS, VEHICLE_CARD_FIELDS } from "constants/fragments";
import { ApolloCacheService } from "services/ApolloCacheService";
import { Collection } from "typings/Collection";
import { Location } from "typings/Location";
import { Model } from "typings/Model";
import { Vehicle } from "typings/Vehicle";
import { GetMakesResponse, VehicleService } from "typings/VehicleService";
import { filterEmptyProperties } from "utils/filterEmptyProperties";

const UPDATE_VEHICLE_SPECS = gql`
  mutation UpdateVehicleSpecs($vehicleId: ID!, $specs: String!) {
    setSpecs(vehicleId: $vehicleId, specs: $specs) {
      id
      specs
    }
  }
`;

const GET_MAKES = gql`
  query GetMakes {
    carMakes {
      name
    }
    motorcycleMakes {
      name
    }
  }
`;

const GET_MODELS = gql`
  query GetModels($make: ID!, $yearFrom: Int, $yearTo: Int) {
    models(make: $make, yearFrom: $yearFrom, yearTo: $yearTo) {
      id
      name
    }
  }
`;

const SET_DISTANCE_UNIT = gql`
  mutation SetDistanceUnit($id: ID!, $distanceUnit: String!) {
    setDistanceUnit(id: $id, distanceUnit: $distanceUnit) {
      id
      distanceUnit
    }
  }
`;

const SET_COLLECTIONS_TO_VEHICLE = gql`
  mutation SetCollectionsToVehicle($id: ID!, $collectionsIds: [ID!]!) {
    setCollectionsToVehicle(
      setCollectionsToVehicleData: { id: $id, collections: $collectionsIds }
    ) {
      id
      collections {
        id
        vehicleCount
        vehicles {
          id
        }
      }
    }
  }
`;

const SET_LOCATION_TO_VEHICLE = gql`
  mutation setLocation($vehicleId: ID!, $locationId: ID) {
    setLocationToVehicle(vehicleId: $vehicleId, locationId: $locationId) {
      id
      locationId
      location {
        id
        vehicleCount
        vehicles {
          id
        }
      }
    }
  }
`;

export default class ApolloVehicleService implements VehicleService {
  constructor(private apolloClient: ApolloClient<any>) {}

  public async getMakes(): Promise<GetMakesResponse> {
    const { data, errors } = await this.apolloClient.query({
      fetchPolicy: "cache-first",
      query: GET_MAKES,
    });

    if (errors) {
      throw errors[0];
    }

    return {
      carMakes: data.carMakes.map((make: { name: string }) => make.name),
      motorcycleMakes: data.motorcycleMakes.map(
        (make: { name: string }) => make.name,
      ),
    };
  }

  public async getModels(
    make: string,
    yearFrom?: number,
    yearTo?: number,
  ): Promise<Model[]> {
    const { data, errors } = await this.apolloClient.query({
      fetchPolicy: "cache-first",
      query: GET_MODELS,
      variables: { make, yearFrom: Number(yearFrom), yearTo: Number(yearTo) },
    });

    if (errors) {
      throw errors[0];
    }

    return data.models;
  }

  public async setCollections(
    id: Vehicle["id"],
    collectionsIds: Array<Collection["id"]>,
  ) {
    const CACHED_VEHICLE_QUERY = gql`
      query CachedCollection($id: ID!) {
        vehicle(id: $id) {
          id
          collections {
            id
          }
        }
      }
    `;

    const cached = this.apolloClient.readQuery({
      query: CACHED_VEHICLE_QUERY,
      variables: { id },
    });

    const { errors } = await this.apolloClient.mutate({
      mutation: SET_COLLECTIONS_TO_VEHICLE,
      variables: { collectionsIds, id },
    });

    if (errors) {
      throw errors[0];
    }

    cached.vehicle.collections.forEach((collection: Collection) => {
      if (!collectionsIds.includes(collection.id)) {
        this.apolloClient.cache.modify<Collection>({
          fields: {
            vehicleCount: (_, { DELETE }) => DELETE,
            vehicles: (_, { DELETE }) => DELETE,
          },
          id: `Collection:${collection.id}`,
        });
      }
    });

    ApolloCacheService.evictDashboardCollection(this.apolloClient.cache);
  }

  public async setDistanceUnit(
    id: Vehicle["id"],
    distanceUnit: Vehicle["distanceUnit"],
  ) {
    const { errors } = await this.apolloClient.mutate({
      mutation: SET_DISTANCE_UNIT,
      variables: { distanceUnit, id },
    });

    if (errors) {
      throw errors[0];
    }
  }

  public async setLocation(
    vehicleId: Vehicle["id"],
    locationId: Location["id"],
    previousLocationId: Location["id"],
  ) {
    const FETCH_LOCATION = gql`
      ${LOCATION_FIELDS}
      ${VEHICLE_CARD_FIELDS}
      query FetchLocation($id: ID!) {
        location(id: $id) {
          ...LocationFields
          vehicles {
            ...VehicleCardFields
          }
        }
      }
    `;

    const { errors } = await this.apolloClient.mutate({
      mutation: SET_LOCATION_TO_VEHICLE,
      variables: { locationId, vehicleId },
    });

    if (errors) {
      throw errors[0];
    }

    if (previousLocationId) {
      await this.apolloClient.query({
        fetchPolicy: "network-only",
        query: FETCH_LOCATION,
        variables: { id: previousLocationId },
      });
    }

    ApolloCacheService.evictDashboardLocation(this.apolloClient.cache);
  }

  public async setSpecs(
    vehicleId: Vehicle["id"],
    specs: Vehicle["specs"],
  ): Promise<void> {
    const { errors } = await this.apolloClient.mutate({
      mutation: UPDATE_VEHICLE_SPECS,
      variables: {
        specs: Buffer.from(
          JSON.stringify(filterEmptyProperties(specs ?? {})),
        ).toString("base64"),
        vehicleId,
      },
    });

    if (errors) {
      throw errors[0];
    }
  }
}
