import { IconDefinition } from "@fortawesome/fontawesome-svg-core";
import Alert from "@mui/material/Alert";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import Stack from "@mui/material/Stack";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import { Breakpoint } from "@mui/system";
import { FormEventHandler, memo, ReactNode } from "react";
import { FormProvider, UseFormReturn } from "react-hook-form";
import { useTranslation } from "react-i18next";

import { ActionBoxResponsive } from "components/ActionBoxResponsive/ActionBoxResponsive";
import { DialogHeader } from "components/dialogs/DialogHeader";

type FormDialogProps = {
  children: ReactNode;
  form: UseFormReturn<any>;
  formId: string;
  icon?: IconDefinition;
  maxWidth: Breakpoint;
  onClose: () => void;
  onSubmit: FormEventHandler<HTMLFormElement>;
  open: boolean;
  /**
   * translated submit button text
   * @default t("save")
   */
  submit?: string;
  /**
   * translated subtitle
   */
  subtitle?: ReactNode | string;
  /**
   * translated title
   */
  title: ReactNode | string;
};

export const FormDialog = memo(function FormDialog({
  children,
  form,
  formId,
  icon,
  maxWidth,
  onClose,
  onSubmit,
  open,
  submit,
  subtitle,
  title,
}: FormDialogProps) {
  const { t } = useTranslation();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const {
    formState: { errors, isValid },
  } = form;

  return (
    <Dialog
      aria-describedby="alert-dialog-description"
      aria-labelledby="alert-dialog-title"
      fullScreen={isMobile}
      fullWidth
      maxWidth={maxWidth}
      onClick={(e) => e.stopPropagation()}
      onClose={onClose}
      open={open}
      sx={{
        "& .MuiDialog-paper": {
          boxShadow: theme.shadows[2],
          overflowY: "visible",
        },
      }}
    >
      <FormProvider {...form}>
        <DialogHeader
          icon={icon}
          onClose={onClose}
          subtitle={subtitle}
          title={title}
        />

        <DialogContent>
          <Stack sx={{ gap: 2 }}>
            {children}

            {errors?.root && (
              <Alert data-testid="form-root-error" severity="error">
                {errors.root.message}
              </Alert>
            )}
          </Stack>
        </DialogContent>

        <DialogActions sx={{ backgroundColor: "background.default" }}>
          <ActionBoxResponsive>
            <form id={formId} onSubmit={onSubmit}>
              <Button
                color="primary"
                data-testid="save-button"
                disabled={!isValid}
                fullWidth={isMobile}
                size="medium"
                type="submit"
                variant="contained"
              >
                {submit ?? t("save")}
              </Button>
            </form>

            {onClose && (
              <Button
                color="secondary"
                data-testid="cancel-button"
                fullWidth={isMobile}
                onClick={onClose}
                size="medium"
                type="button"
                variant="text"
              >
                {t("cancel")}
              </Button>
            )}
          </ActionBoxResponsive>
        </DialogActions>
      </FormProvider>
    </Dialog>
  );
});
