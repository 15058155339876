import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFnsV3";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { installTwicPics } from "@twicpics/components/react";
import { enGB } from "date-fns/locale/en-GB";
import PubSub from "pubsub-js";
import React from "react";
import { createRoot } from "react-dom/client";

import "i18n/config";

import "@fontsource-variable/source-sans-3";

import { BREAKPOINTS } from "constants/layout";
import { AppThemeContextProvider } from "contexts/useAppThemeContext";
import { initGtm } from "services/gtm";
import { initSentry } from "services/sentry";
import ServiceProvider from "services/ServiceProvider";

import App from "./App";

import "@twicpics/components/style.css";

import "./App.css";

initGtm();

initSentry();

ServiceProvider.init();

// touch the PubSub object to avoid tree shaking
// eslint-disable-next-line @typescript-eslint/no-unused-expressions
PubSub.version;

installTwicPics({
  breakpoints: BREAKPOINTS.values,
  domain: import.meta.env.REACT_APP_TWICPICS_URL
    ? import.meta.env.REACT_APP_TWICPICS_URL
    : "http://localhost:4566",
  // env: "debug",
  step: 50,
});

const container = document.getElementById("root");

if (container) {
  const root = createRoot(container);

  root.render(
    <React.StrictMode>
      <LocalizationProvider adapterLocale={enGB} dateAdapter={AdapterDateFns}>
        <AppThemeContextProvider>
          <App />
        </AppThemeContextProvider>
      </LocalizationProvider>
    </React.StrictMode>,
  );
}
