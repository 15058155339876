import { gql, useLazyQuery } from "@apollo/client";
import { AlertColor } from "@mui/material/Alert";
import Button from "@mui/material/Button";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";

import {
  VAULT_DOCUMENT_FIELDS,
  VEHICLE_CORE_FIELDS,
} from "constants/fragments";
import { useMessaging } from "contexts/Messaging/useMessagingContext";
import { useNavigation } from "hooks/useNavigation";
import { Media } from "typings/Media";
import {
  TextDetectionStatus,
  translatedTextDetectionStatus,
} from "typings/TextDetection";
import { EventTopics } from "typings/Topics";

const QUERY = gql`
  ${VAULT_DOCUMENT_FIELDS}
  ${VEHICLE_CORE_FIELDS}
  query FetchTextDetectionProgress($id: ID!) {
    media(id: $id) {
      ...VaultDocumentFields
      vehicle {
        ...VehicleCoreFields
      }
    }
  }
`;

const variantByStatus: Record<TextDetectionStatus, AlertColor> = {
  [TextDetectionStatus.PARSING_FAILED]: "error",
  [TextDetectionStatus.PARSING_SUCCEEDED]: "success",
  [TextDetectionStatus.TEXT_DETECTION_FAILED]: "error",
  [TextDetectionStatus.TEXT_DETECTION_FINISHED]: "info",
  [TextDetectionStatus.TEXT_DETECTION_STARTED]: "info",
  [TextDetectionStatus.TEXT_DETECTION_SUCCEEDED]: "info",
};

export const useNotifyOnTextDetectionProgress = () => {
  const { t } = useTranslation();

  const { goToVehicleLogbookOverview } = useNavigation();

  const [query] = useLazyQuery<{ media: Media }>(QUERY);

  const { dismiss, enqueueMessage } = useMessaging();

  useEffect(() => {
    const handleEvent = async (entityId: string) => {
      const { data } = await query({
        fetchPolicy: "network-only",
        query: QUERY,
        variables: { id: entityId },
      });

      if (!data) return;

      const { historyRecord, textDetections } = data.media;

      const latest = textDetections?.[0];

      if (!latest) return;

      const action = historyRecord?.vehicleId && (
        <Button
          aria-label="close"
          color="inherit"
          onClick={() =>
            goToVehicleLogbookOverview(
              historyRecord?.vehicleId,
              historyRecord?.id,
              historyRecord?.ownerId,
            )
          }
          size="small"
        >
          {t("goToDetail")}
        </Button>
      );

      enqueueMessage({
        action,
        autoHideDuration: 15000, // 15 seconds
        text: translatedTextDetectionStatus[latest.status],
        variant: variantByStatus[latest.status],
      });
    };

    const created = PubSub.subscribe(
      EventTopics.TextDetectionCreated,
      async (_, { entityId }) => handleEvent(entityId),
    );

    const updated = PubSub.subscribe(
      EventTopics.TextDetectionUpdated,
      async (_, { entityId }) => handleEvent(entityId),
    );

    return () => {
      PubSub.unsubscribe(created);
      PubSub.unsubscribe(updated);
    };
  }, [enqueueMessage, dismiss, query]);
};
