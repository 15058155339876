export enum MimeType {
  ApplicationPdf = "application/pdf",
  CommaSeparatedValues = "text/csv",
  ImageAvif = "image/avif",
  ImageJpeg = "image/jpeg",
  ImagePng = "image/png",
  ImageWebp = "image/webp",
  MicrosoftExcel = "application/vnd.ms-excel",
  MicrosoftExcelOpenXML = "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
  MicrosoftWord = "application/msword",
  MicrosoftWordOpenXML = "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
  ModelMatterport = "model/matterport",
  VideoVimeo = "video/vimeo",
  VideoYoutube = "video/youtube",
}

export const isVideo = (
  mimeType?: MimeType,
): mimeType is MimeType.VideoVimeo | MimeType.VideoYoutube =>
  !!mimeType &&
  (mimeType === MimeType.VideoYoutube || mimeType === MimeType.VideoVimeo);

export const isModel = (
  mimeType?: MimeType,
): mimeType is MimeType.ModelMatterport =>
  !!mimeType && mimeType === MimeType.ModelMatterport;
