import CircularProgress from "@mui/material/CircularProgress";
import Stack from "@mui/material/Stack";
import { memo } from "react";

export const LoadingSpinner = memo(function LoadingSpinner() {
  return (
    <Stack
      data-testid="loading"
      sx={{
        alignItems: "center",
        height: (theme) => theme.spacing(40),
        justifyContent: "center",
        width: "100%",
      }}
    >
      <CircularProgress color="primary" size={40} />
    </Stack>
  );
});
