import Alert from "@mui/material/Alert";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Container from "@mui/material/Container";
import Paper from "@mui/material/Paper";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router";

import FerrariJackedFront from "assets/FerrariJackedFront";
import { reload } from "utils/window";

const isDevelopment = import.meta.env.REACT_APP_ENVIRONMENT !== "production";

const ErrorPage = ({ error }: { error: any }) => {
  const { t } = useTranslation();

  const navigate = useNavigate();

  return (
    <Container
      maxWidth="md"
      sx={{
        alignItems: "center",
        display: "flex",
        height: "100%",
        justifyContent: "center",
      }}
    >
      <Stack component={Paper} sx={{ gap: 4, p: 4, width: "100%" }}>
        <Typography sx={{ fontWeight: 400 }} variant="h1">
          {t("error.boundary.title")}
        </Typography>

        <Box sx={{ display: "flex", justifyContent: "center", width: "100%" }}>
          <FerrariJackedFront height="200" width="400" />
        </Box>

        <Typography variant="body1">
          {t("error.boundary.description")}
        </Typography>

        {isDevelopment && error?.stack && (
          <Alert severity="error">
            <Typography variant="body2">{error.stack}</Typography>
          </Alert>
        )}

        <Stack
          direction="row"
          sx={{
            gap: 4,
            justifyContent: "space-between",
          }}
        >
          <Button
            color="primary"
            onClick={() => navigate(-1)}
            sx={{ minWidth: "fit-content", whiteSpace: "nowrap" }}
            type="button"
            variant="outlined"
          >
            {t("goBack")}
          </Button>

          <Button
            color="primary"
            onClick={() => reload({ force: true })}
            sx={{ minWidth: "fit-content", whiteSpace: "nowrap" }}
            type="button"
            variant="outlined"
          >
            {t("reload")}
          </Button>
        </Stack>
      </Stack>
    </Container>
  );
};

export default ErrorPage;
