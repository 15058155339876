import IconButton from "@mui/material/IconButton";
import InputAdornment from "@mui/material/InputAdornment";
import Stack from "@mui/material/Stack";
import { ClearIcon } from "@mui/x-date-pickers";
import { ReactNode } from "react";
import { Controller, useFormContext } from "react-hook-form";

import { TextField, TextFieldProps } from "./TextField";

export type TextFieldControlledProps = {
  clearable?: boolean;
  endAdornment?: ReactNode;
  name: string;
  startAdornment?: ReactNode;
} & TextFieldProps;

type ClearButtonProps = {
  children?: ReactNode;
  onClick: () => void;
  value?: string;
};

const ClearButton = ({ children, onClick, value }: ClearButtonProps) => (
  <InputAdornment
    position="end"
    sx={{ alignItems: "center", display: "inline-flex" }}
  >
    <>
      {value && (
        <IconButton onClick={onClick} size="small">
          <ClearIcon fontSize="small" />
        </IconButton>
      )}

      {children && children}
    </>
  </InputAdornment>
);

export const TextFieldControlled = ({
  clearable,
  endAdornment,
  error: errorProp,
  helperText,
  name,
  slotProps,
  startAdornment,
  ...rest
}: TextFieldControlledProps) => {
  const { control } = useFormContext();

  return (
    <Controller
      control={control}
      name={name}
      render={({
        field: { onChange, value = "" },
        fieldState: { error, invalid },
      }) => (
        <TextField
          {...rest}
          error={invalid || !!errorProp}
          helperText={error?.message ?? helperText}
          name={name}
          onChange={(e) => onChange(e.target.value)}
          slotProps={{
            htmlInput: {
              "data-testid": `${name}-input`,
              ...slotProps?.htmlInput,
            },
            input: {
              ...slotProps?.input,
              endAdornment: (
                <Stack direction="row" sx={{ alignItems: "center", gap: 0.5 }}>
                  {clearable && (
                    <ClearButton onClick={() => onChange("")} value={value} />
                  )}
                  {endAdornment && endAdornment}
                </Stack>
              ),
              startAdornment,
            },
          }}
          value={value}
        />
      )}
    />
  );
};
