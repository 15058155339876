import { ApolloCache } from "@apollo/client";

const dashboardQueries = ["historyRecords", "pricePoints", "activityLogs"];

// eslint-disable-next-line @typescript-eslint/no-extraneous-class
export class ApolloCacheService {
  public static evictDashboardCollection(cache: ApolloCache<any>) {
    const root = cache?.extract()?.ROOT_QUERY;

    if (!root) return;

    const keys = Object.keys(root);

    dashboardQueries.forEach((query) =>
      ApolloCacheService.evictQueryWithArg(cache, keys, query, "collectionId"),
    );

    cache.gc();
  }

  public static evictDashboardLocation(cache: ApolloCache<any>) {
    const root = cache?.extract()?.ROOT_QUERY;

    if (!root) return;

    const keys = Object.keys(root);

    dashboardQueries.forEach((query) =>
      ApolloCacheService.evictQueryWithArg(cache, keys, query, "locationId"),
    );

    cache.gc();
  }

  public static evictLocations(cache: ApolloCache<any>) {
    const root = cache?.extract()?.ROOT_QUERY;

    if (!root) return;

    const keys = Object.keys(root);

    ApolloCacheService.evictQueryWithArg(
      cache,
      keys,
      "locations",
      "collectorId",
    );

    cache.gc();
  }

  private static evictQueryWithArg(
    cache: ApolloCache<any>,
    keys: string[],
    fieldName: string,
    arg: string,
  ) {
    // keys are of the form:
    // "historyRecords({"collectionId":"1", limit: 1, highlight: true})"
    // "collections({"collectorId":"1"})"
    // "pricePoints"

    if (!keys || keys.length === 0) return;

    keys.forEach((key) => {
      if (key.startsWith(fieldName)) {
        const matches = key.match(/\((.*)\)/);

        const args = JSON.parse(matches?.[1] ?? "{}");

        if (args?.[arg]) {
          cache.evict({
            args,
            fieldName,
            id: "ROOT_QUERY",
          });
        }
      }
    });
  }
}
