import { SuspenseWrapper } from "components/Layout/SuspenseWrapper";
import { ServiceModal } from "components/Service/ServiceModal";
import { useModalWithSubscription } from "hooks/useModalWithSubscription";
import { Service } from "typings/Service";
import { DialogTopics, ServiceTopics } from "typings/Topics";

export const ServiceDialogs = () => {
  const { handleClose: handleCloseServiceEdit, object: toEdit } =
    useModalWithSubscription<Service>(
      DialogTopics.EditService,
      ServiceTopics.Updated,
    );

  return (
    <SuspenseWrapper data-testid="service-dialogs">
      {!!toEdit && (
        <ServiceModal onClose={handleCloseServiceEdit} service={toEdit} />
      )}
    </SuspenseWrapper>
  );
};
