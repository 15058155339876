import Box from "@mui/material/Box";
import { useTheme } from "@mui/material/styles";
import { memo } from "react";

import LogoSprite from "assets/logo_sprite.svg";
import { isDefaultOrganization, Organizations } from "typings/Organization";

const viewBoxByOrganization: Record<string, string> = {
  acme: "0 0 170 48",
  ascari: "0 0 278 58",
  bi: "0 0 250 23",
  driven: "0 0 237 48",
};

type LogoProps = {
  organizationId: Organizations;
  shapeColor?: string;
  textColor?: string;
};

export const PartnerLogo = memo(function OrganizationLogo({
  organizationId,
  shapeColor,
  textColor,
}: LogoProps) {
  if (isDefaultOrganization(organizationId)) {
    return null;
  }

  return (
    <Logo
      organizationId={organizationId}
      shapeColor={shapeColor}
      textColor={textColor}
    />
  );
});

const LogoSvg = memo(function LogoSvg({
  organizationId,
  shapeColor,
  textColor,
}: LogoProps) {
  const theme = useTheme();

  const shapeColorByOrganization: Record<string, string> = {
    acme: theme.palette.acme.main,
    ascari: theme.palette.ascari.main,
    bi: theme.palette.bi.main,
    driven: theme.palette.driven.main,
  };

  return (
    <Box
      data-testid={`logo-${organizationId}`}
      sx={{
        "--logo-shape":
          shapeColor ?? shapeColorByOrganization[organizationId ?? "driven"],
        "--logo-text": textColor ?? theme.palette.text.primary,
        display: "flex",
        width: "100%",
      }}
    >
      <svg
        height="100%"
        preserveAspectRatio="xMinYMid meet"
        viewBox={viewBoxByOrganization[organizationId ?? "driven"]}
        width="100%"
        xmlns="http://www.w3.org/2000/svg"
        xmlnsXlink="http://www.w3.org/1999/xlink"
      >
        <use xlinkHref={`${LogoSprite}#${organizationId ?? "driven"}`} />
      </svg>
    </Box>
  );
});

export const Logo = memo(function Logo({
  organizationId,
  shapeColor,
  textColor,
}: LogoProps) {
  const theme = useTheme();

  switch (organizationId) {
    case Organizations.ACME:
      return (
        <LogoSvg
          organizationId={organizationId}
          shapeColor={shapeColor}
          textColor={textColor}
        />
      );
    case Organizations.ASCARI:
      return (
        <LogoSvg
          organizationId={organizationId}
          shapeColor={shapeColor}
          textColor={textColor}
        />
      );
    case Organizations.BI:
      return (
        <LogoSvg
          organizationId={organizationId}
          shapeColor={shapeColor}
          textColor={textColor}
        />
      );
    case Organizations.CURATED:
      return (
        <Box
          component="img"
          data-testid={`logo-${organizationId}`}
          src="/img/logocurated.png"
          sx={{
            display: "block",
            maxWidth: "100%",
            objectFit: "contain",
            objectPosition: "left",
          }}
        />
      );
    case Organizations.DRIVEN:
      return (
        <LogoSvg
          organizationId={organizationId}
          shapeColor={shapeColor}
          textColor={textColor}
        />
      );
    case Organizations.ILR:
      return (
        <Box
          component="img"
          data-testid={`logo-${organizationId}`}
          src="/img/logoilr.png"
          sx={{
            display: "block",
            maxWidth: theme.spacing(5),
            width: "auto",
          }}
        />
      );
    case Organizations.KESSEL:
      return (
        <Box
          component="img"
          data-testid={`logo-${organizationId}`}
          src="/img/logokessel.png"
          sx={{ maxWidth: "100%" }}
        />
      );
    default:
      return null;
  }
});
