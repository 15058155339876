import { Outlet } from "react-router";

import { CollectionDialogs } from "components/Collection/dialogs/CollectionDialogs";
import { CollectorDialogs } from "components/Collector/dialogs/CollectorDialogs";
import { DataRoomDialogs } from "components/DataRoom/dialogs/DataRoomDialogs";
import { EncryptionDialogs } from "components/encryption/dialogs/EncryptionDialogs";
import { HistoryRecordDialogs } from "components/HistoryRecord/dialogs/HistoryRecordDialogs";
import { SuspenseWrapper } from "components/Layout/SuspenseWrapper";
import { LocationDialogs } from "components/Location/dialogs/LocationDialogs";
import { ReminderDialogs } from "components/Reminder/dialogs/ReminderDialogs";
import { ServiceDialogs } from "components/Service/ServiceDialogs";
import { ServiceRequestDialogs } from "components/ServiceRequest/dialogs/ServiceRequestDialogs";
import { SubscriptionDialogs } from "components/subscription/dialogs/SubscriptionDialogs";
import { VaultDialogs } from "components/Vault/VaultDialogs";
import { VehicleDialogs } from "components/Vehicle/dialogs/VehicleDialogs";

export const ContentDialogs = () => (
  <>
    <SuspenseWrapper>
      <Outlet />
    </SuspenseWrapper>

    <SuspenseWrapper>
      <CollectionDialogs />
      <CollectorDialogs />
      <DataRoomDialogs />
      <EncryptionDialogs />
      <HistoryRecordDialogs />
      <LocationDialogs />
      <ReminderDialogs />
      <ServiceRequestDialogs />
      <ServiceDialogs />
      <VaultDialogs />
      <VehicleDialogs />
      <SubscriptionDialogs />
    </SuspenseWrapper>
  </>
);
