import Box from "@mui/material/Box";
import Container, { ContainerProps } from "@mui/material/Container";
import Stack from "@mui/material/Stack";
import { useTheme } from "@mui/material/styles";
import Typography from "@mui/material/Typography";
import { memo, NamedExoticComponent } from "react";
import { useTranslation } from "react-i18next";

import { SuspenseWrapper } from "components/Layout/SuspenseWrapper";
import { Logo } from "components/Logo/Logo";
import { HORIZONTAL_APP_BAR_HEIGHT } from "constants/layout";
import { useAppTheme } from "contexts/useAppThemeContext";

export type LayoutProps = {
  AppBar?: NamedExoticComponent<object>;
  onlyHorizontalAppBar?: boolean;
  verticalAppBarWidth?: number;
} & ContainerProps;

export const Layout = memo(function Layout({
  AppBar,
  children,
  onlyHorizontalAppBar = false,
  sx,
  verticalAppBarWidth = 0,
  ...rest
}: LayoutProps) {
  const theme = useTheme();
  const { t } = useTranslation();

  const { isPartnerTheme } = useAppTheme();

  const hasAppBar = onlyHorizontalAppBar && !!AppBar;

  return (
    <>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
        }}
      >
        {!!AppBar && (
          <SuspenseWrapper>
            <AppBar />
          </SuspenseWrapper>
        )}
        <Box
          component="main"
          sx={{
            backgroundColor: theme.palette.background.default,
            display: "flex",
            flexDirection: "column",
            minHeight: hasAppBar
              ? `calc(100dvh - ${theme.spacing(HORIZONTAL_APP_BAR_HEIGHT)})`
              : "100dvh",
            pl: { md: theme.spacing(verticalAppBarWidth), xs: 0 },
            pt: {
              md:
                verticalAppBarWidth || !AppBar
                  ? 0
                  : theme.spacing(HORIZONTAL_APP_BAR_HEIGHT),
              xs: AppBar ? theme.spacing(HORIZONTAL_APP_BAR_HEIGHT) : 0,
            },
          }}
        >
          <Container
            {...rest}
            sx={{
              display: "flex",
              flex: "1 1 100%",
              minHeight: hasAppBar
                ? `calc(100dvh - ${theme.spacing(HORIZONTAL_APP_BAR_HEIGHT)})`
                : {
                    md: "100dvh",
                    xs: AppBar
                      ? `calc(100dvh - ${theme.spacing(
                          HORIZONTAL_APP_BAR_HEIGHT,
                        )})`
                      : 0,
                  },
              paddingBottom: theme.spacing(2),
              paddingTop: hasAppBar
                ? "inherit"
                : {
                    md: theme.spacing(9),
                    sm: theme.spacing(4),
                    xs: theme.spacing(2),
                  },
              paddingX: { md: theme.spacing(3), xs: theme.spacing(1.5) },
              ...sx,
            }}
          >
            <Stack direction="column" flex="1 1 100%" width="100%">
              {children}
            </Stack>
          </Container>
        </Box>
      </Box>

      {isPartnerTheme && (
        <Stack
          alignItems="center"
          direction="row"
          gap={0.5}
          sx={{
            bottom: hasAppBar ? theme.spacing(2) : "initial",
            display: { md: "flex", xs: "none" },
            height: theme.spacing(2.5),
            position: "fixed",
            right: theme.spacing(3),
            top: hasAppBar ? "initial" : theme.spacing(2),
          }}
        >
          <Typography color={theme.palette.text.disabled} variant="caption">
            {t("poweredBy")}
          </Typography>
          <Box
            sx={{
              width: theme.spacing(8),
            }}
          >
            <Logo
              organizationId="driven"
              shapeColor={theme.palette.text.disabled}
              textColor={theme.palette.text.disabled}
            />
          </Box>
        </Stack>
      )}
    </>
  );
});
