import { useQuery } from "@apollo/client";
import { getCurrentScope } from "@sentry/react";
import {
  createContext,
  lazy,
  PropsWithChildren,
  useContext,
  useEffect,
  useMemo,
} from "react";
import { useResolve } from "react-jpex";

import { useConfirmDataRoomMembership } from "components/DataRoom/forms/useConfirmDataRoomMembership";
import { useGuardModal } from "components/GuardModal/useGuardModal";
import {
  USER_ID_FIELD,
  USER_LOGGED_IN_EVENT,
  USER_LOGGED_OUT_EVENT,
  USER_SET_ID_EVENT,
  USER_SIGNUP_EVENT,
} from "constants/tracking";
import { useAppTheme } from "contexts/useAppThemeContext";
import { useConfirmCrewMembership } from "hooks/useConfirmCrewMembership";
import { useInviteCode } from "hooks/useCrewInviteCode";
import { useForceRefreshTokenOnSubscriptionChanges } from "hooks/useForceRefreshTokenOnSubscriptionChanges";
import { useTracking } from "hooks/useTracking";
import i18n from "i18n/config";
import { FETCH_DETAILS } from "services/ApolloCollectorService";
import { AuthenticationService } from "typings/AuthenticationService";
import { Collector } from "typings/Collector";
import { Role } from "typings/Role";
import { UserTopics } from "typings/Topics";
import { UserPreferenceService } from "typings/UserPreferenceService";
import { registerSignUpConsent } from "utils/consent";
import { sendToGTM } from "utils/sendToGTM";

const GuardModal = lazy(() => import("components/GuardModal/GuardModal"));

const AuthenticatedUserContext = createContext<Collector>({});

AuthenticatedUserContext.displayName = "Driven::AuthenticatedUser";

export const useAuthenticatedUser = () => useContext(AuthenticatedUserContext);

export const AuthenticatedUserContextProvider = ({
  children,
}: PropsWithChildren) => {
  const authenticationService = useResolve<AuthenticationService>(
    "AuthenticationService",
  );
  const userPreferenceService = useResolve<UserPreferenceService>(
    "UserPreferenceService",
  );

  const { setTheme } = useAppTheme();

  const crewInviteCode = userPreferenceService.getCrewInviteCode();
  const dataRoomInviteCode = userPreferenceService.getDataRoomInviteCode();
  const authUserId = authenticationService.getAuthUserId();

  const actingOnBehalfOfId = authenticationService.getOnBehalfOfId();
  const isAdmin = authenticationService.isAdmin();
  const isMfaRequired = authenticationService.isMfaRequired();

  const { handleClose, mode, redirectCallback } = useGuardModal();
  const { confirmCrewMembership } = useConfirmCrewMembership();
  const { confirm: confirmDataRoomMembership } = useConfirmDataRoomMembership();

  useTracking();

  useInviteCode();

  const skipQuery = isAdmin || isMfaRequired || !authUserId;

  const { data } = useQuery<{ collector: Collector }>(FETCH_DETAILS, {
    skip: skipQuery,
    variables: { id: actingOnBehalfOfId ?? authUserId },
  });

  useForceRefreshTokenOnSubscriptionChanges(data?.collector);

  useEffect(() => {
    const loggedId = PubSub.subscribe(UserTopics.LoggedIn, async () => {
      userPreferenceService.clearSessionSettings();

      if (
        authenticationService.isCollector() ||
        authenticationService.hasOrganizationPrivileges()
      ) {
        const id = authenticationService.getAuthUserId();

        if (data?.collector) {
          sendToGTM({
            event: USER_SET_ID_EVENT,
            [USER_ID_FIELD]: id,
          });

          sendToGTM({
            event: USER_LOGGED_IN_EVENT,
            [USER_ID_FIELD]: id,
          });
        }
      }
    });

    const signedUp = PubSub.subscribe(
      UserTopics.SignedUp,
      async (_msg, payload: { email: string }) => {
        userPreferenceService.clearSessionSettings();

        const id = authenticationService.getAuthUserId();

        if (id && payload?.email) {
          sendToGTM({
            event: USER_SIGNUP_EVENT,
            [USER_ID_FIELD]: id,
          });

          registerSignUpConsent({ email: payload.email, id });
        }
      },
    );

    const loggedOut = PubSub.subscribe(UserTopics.LoggedOut, () => {
      userPreferenceService.clearSessionSettings();

      sendToGTM({
        event: USER_LOGGED_OUT_EVENT,
      });

      sendToGTM({
        event: USER_SET_ID_EVENT,
        [USER_ID_FIELD]: undefined,
      });

      getCurrentScope().setUser(null);
    });

    return () => {
      PubSub.unsubscribe(loggedId);
      PubSub.unsubscribe(loggedOut);
      PubSub.unsubscribe(signedUp);
    };
  }, [authenticationService, data]);

  useEffect(() => {
    if (crewInviteCode && data?.collector?.id) {
      void confirmCrewMembership(crewInviteCode);
    }
  }, [crewInviteCode, data]);

  useEffect(() => {
    if (dataRoomInviteCode && data?.collector?.id) {
      void confirmDataRoomMembership(dataRoomInviteCode);
    }
  }, [dataRoomInviteCode, data]);

  const value = useMemo(() => {
    if (isAdmin) {
      return {
        role: Role.ADMIN,
        username: "Admin",
      } as Collector;
    }

    return data
      ? {
          ...data?.collector,
          actingOnBehalfOfId,
        }
      : ({} as Collector);
  }, [data, actingOnBehalfOfId, isAdmin]);

  useEffect(() => {
    if (data?.collector?.language) {
      void i18n.changeLanguage(data?.collector?.language);
    }
  }, [data?.collector?.language, i18n]);

  useEffect(() => {
    if (data?.collector?.appTheme) {
      setTheme(data?.collector?.appTheme);
    }
  }, [data?.collector?.appTheme]);

  return (
    <AuthenticatedUserContext.Provider value={value}>
      <>
        {children}

        {!!mode && (
          <GuardModal
            initialMode={mode}
            isLoggedIn={!isMfaRequired && !!authUserId}
            onClose={handleClose}
            redirectCallback={redirectCallback}
          />
        )}
      </>
    </AuthenticatedUserContext.Provider>
  );
};
