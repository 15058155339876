import { SpacingUnit } from "typings/SpacingUnit";

export const PHOTO_HEIGHT = 120;

export const CARD_COVER_PHOTO_HEIGHT = 150;

export const DETAIL_COVER_PHOTO_HEIGHT = 200;

export const BORDER_RADIUS_PX = 2;

export const BUTTON_BORDER_RADIUS_PX = 22;

export const HORIZONTAL_APP_BAR_HEIGHT: SpacingUnit = 9;

export const VERTICAL_APP_BAR_WIDTH: SpacingUnit = 30;

export const STRIPED_DIVIDER_HEIGHT: SpacingUnit = 3.5;

export const STRIPED_TWO_LINE_DIVIDER_HEIGHT: SpacingUnit = 20;

export const WHITE = "hsla(0, 0%, 100%, 1)";

export const BLACK = "hsla(0, 0%, 0%, 1)";

export const BREAKPOINTS = {
  values: {
    lg: 1200,
    md: 900,
    sm: 600,
    xl: 1536,
    xs: 0,
  },
};
