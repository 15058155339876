import {
  faEnvelope,
  faFingerprint,
  faMobileButton,
} from "@fortawesome/pro-light-svg-icons";

import { MfaOptions } from "typings/Mfa";

export const MFA_METHOD_OPTIONS: MfaOptions = {
  email: {
    cantAccessKey: "mfaCantAccessEmail",
    claimKey: "confirmMfaEmailClaim",
    icon: faEnvelope,
    useKey: "mfaUseEmail",
  },
  otp: {
    cantAccessKey: "mfaCantAccessAuthApp",
    claimKey: "confirmMfaAuthAppClaim",
    icon: faMobileButton,
    useKey: "mfaUseAuthApp",
  },
  webauthn: {
    cantAccessKey: "mfaCantAccessWebAuthn",
    claimKey: "confirmMfaWebAuthnClaim",
    icon: faFingerprint,
    useKey: "mfaUseWebAuthn",
  },
};
