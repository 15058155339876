import { IconDefinition } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import { useTheme } from "@mui/material/styles";
import Typography from "@mui/material/Typography";
import { memo, ReactNode } from "react";

import { DialogCloseButton } from "components/DialogCloseButton/DialogCloseButton";

type DialogHeaderProps = {
  icon?: IconDefinition;
  onClose?: () => void;
  subtitle?: ReactNode | string;
  title?: ReactNode | string;
};

export const DialogHeader = memo(function DialogHeader({
  icon,
  onClose,
  subtitle,
  title = "",
}: DialogHeaderProps) {
  const theme = useTheme();

  return (
    <Stack
      sx={{
        borderTop: `2px solid ${theme.palette.divider}`,
        flexDirection: "column",
        gap: 2,
        justifyContent: "center",
        px: 3,
        py: 1.5,
      }}
    >
      <Stack sx={{ alignItems: "center", flexDirection: "row", gap: 1 }}>
        {icon && (
          <FontAwesomeIcon
            color={theme.palette.primary.main}
            fixedWidth
            icon={icon}
          />
        )}

        <Typography sx={{ alignSelf: "center" }} variant="h5">
          {title}
        </Typography>

        {!!onClose && (
          <Box sx={{ alignItems: "center", display: "flex", ml: "auto" }}>
            <DialogCloseButton onClose={onClose} />
          </Box>
        )}
      </Stack>
      {!!subtitle && <Typography variant="body2">{subtitle}</Typography>}
    </Stack>
  );
});
