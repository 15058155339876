import { closeSnackbar, enqueueSnackbar, SnackbarProvider } from "notistack";
import { createContext, lazy, ReactNode, useContext } from "react";

import { Message } from "typings/Message";

const MessageContent = lazy(() => import("./MessageContent"));

type MessagingContextProviderProps = {
  children: ReactNode;
};

type MessagingContextType = {
  dismiss: (key: string) => void;
  enqueueMessage: (message: Message) => void;
};

const enqueueMessage = (message: Message) => {
  const { autoHideDuration, persist, text } = message;

  enqueueSnackbar(text, {
    autoHideDuration,
    content: (messageKey) => (
      <MessageContent message={message} messageKey={messageKey} />
    ),
    key: message.key,
    persist,
  });
};

const dismiss = (key: string) => closeSnackbar(key);

const defaultContext: MessagingContextType = {
  dismiss,
  enqueueMessage,
};

export const MessagingContext =
  createContext<MessagingContextType>(defaultContext);

MessagingContext.displayName = "Driven::Messaging";

export const useMessaging = () => useContext(MessagingContext);

export const MessagingContextProvider = ({
  children,
}: MessagingContextProviderProps) => {
  return (
    <MessagingContext.Provider value={defaultContext}>
      <SnackbarProvider
        anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
        maxSnack={3}
      />
      {children}
    </MessagingContext.Provider>
  );
};
