import { lazy } from "react";

import { SuspenseWrapper } from "components/Layout/SuspenseWrapper";
import { useModalWithSubscription } from "hooks/useModalWithSubscription";
import { usePublishMessageOnNavigation } from "hooks/useNavigation";
import { Entity } from "typings/Entity";
import { ListView } from "typings/ListView";
import {
  CollectorTopics,
  DialogTopics,
  ListingTopics,
  VehicleTopics,
} from "typings/Topics";
import { Vehicle } from "typings/Vehicle";
import { Wishlist } from "typings/Wishlist";

const EditVehicleModal = lazy(
  () => import("components/Vehicle/dialogs/EditVehicleModal"),
);
const EditGalleryModal = lazy(
  () => import("components/EditGalleryModal/EditGalleryModal"),
);
const ListingModal = lazy(
  () => import("components/Listing/dialogs/ListingModal"),
);
const SaveListViewDialog = lazy(
  () => import("components/Vehicle/dialogs/SaveListViewDialog"),
);
const VehicleManageCollectionsModal = lazy(
  () => import("./VehicleManageCollectionsModal"),
);
const VehicleUpdateLocationModal = lazy(
  () => import("components/Vehicle/dialogs/VehicleUpdateLocationModal"),
);
const VehicleUpdateConsignmentModal = lazy(
  () => import("components/Vehicle/dialogs/VehicleUpdateConsignmentModal"),
);
const AddVehicleModal = lazy(
  () => import("components/Vehicle/dialogs/AddVehicleModal"),
);
const ExternalLinksModal = lazy(
  () => import("components/Vehicle/detail/ExternalLinksModal"),
);
const EditVehicleSpecsModal = lazy(
  () => import("components/Vehicle/dialogs/EditVehicleSpecsModal"),
);
const VehicleStatusModal = lazy(
  () => import("components/Vehicle/dialogs/VehicleStatusModal"),
);
const TagsModal = lazy(() => import("components/Vehicle/dialogs/TagsModal"));
const ImportVehiclesModal = lazy(
  () => import("components/Vehicle/dialogs/ImportVehiclesModal"),
);
const TransferVehicleDialog = lazy(
  () => import("components/Vehicle/dialogs/TransferVehicleDialog"),
);
const AssignOrganizationModal = lazy(
  () => import("components/Vehicle/dialogs/AssignOrganizationModal"),
);

export const VehicleDialogs = () => {
  const { handleClose: handleCloseEditDetails, object: toEditDetails } =
    useModalWithSubscription<Vehicle["id"]>(
      DialogTopics.EditVehicle,
      VehicleTopics.Updated,
    );

  const { handleClose: handleCloseAdd, object: toAdd } =
    useModalWithSubscription<{
      ownerId: string;
      vehicleOverQuota: boolean;
      wishlist: Wishlist;
    }>(DialogTopics.AddVehicle, [VehicleTopics.Added, DialogTopics.Close]);

  const { handleClose: handleCloseManage, object: toManageCollection } =
    useModalWithSubscription<Vehicle>(
      DialogTopics.ManageVehicleCollections,
      VehicleTopics.CollectionsUpdated,
    );

  const { handleClose: handleCloseUpdateLocation, object: toUpdateLocation } =
    useModalWithSubscription<Vehicle>(
      DialogTopics.UpdateVehicleLocation,
      VehicleTopics.LocationUpdated,
    );

  const {
    handleClose: handleCloseUpdateConsignment,
    object: toUpdateConsignment,
  } = useModalWithSubscription<Vehicle>(
    DialogTopics.UpdateVehicleConsignment,
    VehicleTopics.ConsignmentUpdated,
  );

  const { handleClose: handleCloseSaveListViewAs, object: listView } =
    useModalWithSubscription<ListView>(
      DialogTopics.SaveListViewAs,
      CollectorTopics.ListViewUpdated,
    );

  const { handleClose: handleCloseCreateListing, object: toCreateListing } =
    useModalWithSubscription<Vehicle["id"]>(
      DialogTopics.AddListing,
      ListingTopics.Updated,
    );

  const { handleClose: handleCloseEditGallery, object: toEditGallery } =
    useModalWithSubscription<Entity>(DialogTopics.EditVehicleGallery);

  const { handleClose: handleCloseEditSpecs, object: toEditSpecs } =
    useModalWithSubscription<Entity>(
      DialogTopics.EditVehicleSpecs,
      VehicleTopics.Updated,
    );

  const {
    handleClose: handleCloseEditExternalLinks,
    object: toEditExternalLinks,
  } = useModalWithSubscription<Vehicle["id"]>(
    DialogTopics.EditVehicleExternalLinks,
    VehicleTopics.ExternalLinksUpdated,
  );

  const { handleClose: handleCloseEditStatus, object: toEditStatus } =
    useModalWithSubscription<Vehicle>(
      DialogTopics.EditVehicleStatus,
      VehicleTopics.StatusUpdated,
    );

  const { handleClose: handleCloseImportVehicles, object: toImportVehicles } =
    useModalWithSubscription<{ vehicleOverQuota: boolean } & Entity>(
      DialogTopics.ImportVehicles,
      VehicleTopics.VehiclesImported,
    );

  const { handleClose: handleCloseEditTags, object: toEditTags } =
    useModalWithSubscription<Vehicle["id"]>(
      DialogTopics.EditTags,
      VehicleTopics.TagsUpdated,
    );

  const { handleClose: handleCloseTransfer, object: toTransfer } =
    useModalWithSubscription<Vehicle>(DialogTopics.TransferVehicle);

  const {
    handleClose: handleCloseAssignOrganization,
    object: toAssignOrganization,
  } = useModalWithSubscription<Vehicle>(
    DialogTopics.AssignOrganization,
    VehicleTopics.Updated,
  );

  usePublishMessageOnNavigation();

  return (
    <SuspenseWrapper data-testid="vehicle-dialogs">
      {!!toAdd && <AddVehicleModal {...toAdd} onClose={handleCloseAdd} />}

      {!!toEditDetails && (
        <EditVehicleModal id={toEditDetails} onClose={handleCloseEditDetails} />
      )}

      {toManageCollection?.id && (
        <VehicleManageCollectionsModal
          onClose={handleCloseManage}
          vehicleId={toManageCollection.id}
        />
      )}

      {toUpdateLocation && (
        <VehicleUpdateLocationModal
          onClose={handleCloseUpdateLocation}
          vehicle={toUpdateLocation}
        />
      )}

      {toUpdateConsignment && (
        <VehicleUpdateConsignmentModal
          onClose={handleCloseUpdateConsignment}
          vehicle={toUpdateConsignment}
        />
      )}

      {listView && (
        <SaveListViewDialog
          onClose={handleCloseSaveListViewAs}
          record={listView}
        />
      )}

      {toCreateListing && (
        <ListingModal
          onClose={handleCloseCreateListing}
          vehicleId={toCreateListing}
        />
      )}

      {toEditGallery && (
        <EditGalleryModal
          {...toEditGallery}
          entityType="vehicle"
          onClose={handleCloseEditGallery}
        />
      )}

      {toEditSpecs && (
        <EditVehicleSpecsModal
          {...toEditSpecs}
          onClose={handleCloseEditSpecs}
        />
      )}

      {toEditExternalLinks && (
        <ExternalLinksModal
          onClose={handleCloseEditExternalLinks}
          vehicleId={toEditExternalLinks}
        />
      )}

      {toEditStatus && (
        <VehicleStatusModal
          onClose={handleCloseEditStatus}
          vehicle={toEditStatus}
        />
      )}

      {toImportVehicles && (
        <ImportVehiclesModal
          {...toImportVehicles}
          onClose={handleCloseImportVehicles}
        />
      )}

      {toEditTags && (
        <TagsModal onClose={handleCloseEditTags} vehicleId={toEditTags} />
      )}

      {toTransfer && (
        <TransferVehicleDialog
          onClose={handleCloseTransfer}
          vehicle={toTransfer}
        />
      )}

      {toAssignOrganization && (
        <AssignOrganizationModal
          onClose={handleCloseAssignOrganization}
          vehicleId={toAssignOrganization.id}
        />
      )}
    </SuspenseWrapper>
  );
};
