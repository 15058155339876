/* eslint-disable @typescript-eslint/consistent-type-definitions */
import type {} from "@mui/x-date-pickers/themeAugmentation";

import { ChipTypeMap } from "@mui/material/Chip";
import {
  alpha,
  createTheme,
  PaletteColor,
  PaletteColorOptions,
  Theme,
} from "@mui/material/styles";
import { useMemo } from "react";

import { BLACK, BREAKPOINTS, WHITE } from "constants/layout";
import { LIGHT_WARNING } from "constants/palette";
import { DEFAULT_APP_THEME } from "constants/settings";
import {
  borderRadiusByAppTheme,
  buttonBorderRadiusByAppTheme,
  paletteByAppTheme,
  selectionColorByAppTheme,
  typographyByAppTheme,
} from "constants/theming";
import { useAppTheme } from "contexts/useAppThemeContext";
import { AppTheme, MuiMode } from "typings/AppTheme";

interface TypeBorder {
  card: { hover: string; main: string };
  dropZone: string;
}

interface TypePrimaryPalette {
  100: string;
  200: string;
  300: string;
  400: string;
  500: string;
  600: string;
  700: string;
  800: string;
  900: string;
}

interface TypeShadow {
  default: string;
  flag: string;
  hover: string;
}

declare module "@mui/material/styles/createPalette" {
  interface Palette {
    acme: PaletteColor;
    ai: PaletteColor;
    ascari: PaletteColor;
    bi: PaletteColor;
    border: TypeBorder;
    dark: PaletteColor;
    driven: PaletteColor;
    icon: {
      highlightChip: {
        backgroundColor: string;
        color: string;
      };
      verificationStatus: string;
    };
    light: PaletteColor;
    primaryPalette: TypePrimaryPalette;
    shadow: TypeShadow;
  }

  interface PaletteOptions {
    acme?: PaletteColorOptions;
    ascari?: PaletteColorOptions;
    bi?: PaletteColorOptions;
    dark?: PaletteColorOptions;
    driven?: PaletteColorOptions;
    light?: PaletteColorOptions;
  }

  interface TypeBackground {
    backdrop?: string;
    cardEmptyHeader: string;
    cardLip: string;
    chatBubble: {
      other: string;
      self: string;
    };
    dark: string;
    light: string;
    nav: string;
    paperHover: string;
    reminderDateCircle: string;
    timelineConnector: string;
  }

  interface TypeText {
    ai: string;
    dark: string;
    light: string;
  }
}

declare module "@mui/material/Button" {
  interface ButtonPropsColorOverrides {
    dark: true;
    light: true;
  }
  interface ButtonPropsColorOverrides {
    ai: true;
  }
}

declare module "@mui/material/Alert" {
  interface AlertPropsColorOverrides {
    ai: true;
  }
}

declare module "@mui/material/styles" {
  interface TypographyVariants {
    body3: React.CSSProperties;
    hero: React.CSSProperties;
  }

  interface TypographyVariantsOptions {
    body3: React.CSSProperties;
    hero?: React.CSSProperties;
  }
}

declare module "@mui/material/Typography" {
  interface TypographyPropsVariantOverrides {
    body3: true;
    hero: true;
  }
}

declare module "@mui/material/Chip" {
  interface ChipPropsColorOverrides {
    ai: true;
  }
  interface ChipPropsVariantOverrides {
    pastel: true;
  }
}

type ChipColor = ChipTypeMap["props"]["color"];
type ChipVariant = ChipTypeMap["props"]["variant"];

const getDesignTokens = (appTheme: AppTheme) => ({
  breakpoints: BREAKPOINTS,
  components: {
    MuiAlert: {
      styleOverrides: {
        action: {
          alignItems: "center",
          padding: 0,
        },
      },
    },
    MuiAutocomplete: {
      styleOverrides: {
        paper: ({ theme }: { theme: Theme }) => ({
          backdropFilter: "blur(32px) brightness(1.5)",
          backgroundColor: alpha(theme.palette.background.paper, 0.75),
          ...theme.applyStyles(MuiMode.DARK, {
            backdropFilter: "blur(32px) brightness(0.75)",
          }),
        }),
        tag: ({ theme }: { theme: Theme }) => ({
          paddingLeft: theme.spacing(0.5),
        }),
      },
    },
    MuiAvatar: {
      styleOverrides: {
        circular: {
          WebkitMaskImage: "-webkit-radial-gradient(white, black)",
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        contained: {
          "&:active": {
            boxShadow: "none",
          },
          "&:hover": {
            boxShadow: "none",
          },
          boxShadow: "none",
        },
        root: {
          "@media print": {
            display: "none",
          },
          borderRadius: buttonBorderRadiusByAppTheme[appTheme],
        },
      },
    },
    MuiButtonBase: {
      styleOverrides: {
        root: {
          "@media print": {
            display: "none",
          },
        },
      },
    },
    MuiCard: {
      styleOverrides: {
        root: {
          borderColor: paletteByAppTheme[appTheme].border.card.main,
        },
      },
    },
    MuiChip: {
      styleOverrides: {
        deleteIconSmall: {
          margin: 0,
        },
        iconSmall: ({ theme }: { theme: Theme }) => ({
          margin: 0,
          marginLeft: theme.spacing(-0.25),
        }),
        labelSmall: ({ theme }: { theme: Theme }) => ({
          padding: `0 ${theme.spacing(0.5)}`,
        }),
        sizeSmall: ({ theme }: { theme: Theme }) => ({
          fontSize: "0.75rem",
          fontWeight: 600,
          padding: `${theme.spacing(0.25)} ${theme.spacing(0.5)}`,
        }),
      },
      variants: [
        {
          props: {
            color: "default" as ChipColor,
            variant: "pastel" as ChipVariant,
          },
          style: ({ theme }: { theme: Theme }) => ({
            "&:hover": {
              backgroundColor: alpha(theme.palette.text.primary, 0.25),
            },
            backgroundColor: alpha(
              theme.palette.text.primary,
              theme.palette.mode === "light" ? 0.05 : 0.1,
            ),
            color: theme.palette.text.secondary,
          }),
        },
        {
          props: {
            color: "primary" as ChipColor,
            variant: "pastel" as ChipVariant,
          },
          style: ({ theme }: { theme: Theme }) => ({
            "&:hover": {
              backgroundColor: alpha(theme.palette.primary.main, 0.25),
            },
            backgroundColor: alpha(theme.palette.primary.main, 0.1),
            color:
              theme.palette.primary[
                theme.palette.mode === "light" ? "dark" : "main"
              ],
          }),
        },
        {
          props: {
            color: "secondary" as ChipColor,
            variant: "pastel" as ChipVariant,
          },
          style: ({ theme }: { theme: Theme }) => ({
            "&:hover": {
              backgroundColor: alpha(theme.palette.secondary.main, 0.25),
            },
            backgroundColor: alpha(
              theme.palette.secondary.main,
              theme.palette.mode === "light" ? 0.05 : 0.1,
            ),
            color:
              theme.palette.secondary[
                theme.palette.mode === "light" ? "dark" : "main"
              ],
          }),
        },
        {
          props: {
            color: "success" as ChipColor,
            variant: "pastel" as ChipVariant,
          },
          style: ({ theme }: { theme: Theme }) => ({
            "&:hover": {
              backgroundColor: alpha(theme.palette.success.main, 0.25),
            },
            backgroundColor: alpha(theme.palette.success.main, 0.1),
            color:
              theme.palette.success[
                theme.palette.mode === "light" ? "dark" : "main"
              ],
          }),
        },
        {
          props: {
            color: "info" as ChipColor,
            variant: "pastel" as ChipVariant,
          },
          style: ({ theme }: { theme: Theme }) => ({
            "&:hover": {
              backgroundColor: alpha(theme.palette.info.main, 0.25),
            },
            backgroundColor: alpha(theme.palette.info.main, 0.1),
            color:
              theme.palette.info[
                theme.palette.mode === "light" ? "dark" : "light"
              ],
          }),
        },
        {
          props: {
            color: "warning" as ChipColor,
            variant: "pastel" as ChipVariant,
          },
          style: ({ theme }: { theme: Theme }) => ({
            backgroundColor: alpha(theme.palette.warning.main, 0.1),
            color: LIGHT_WARNING[800],
            ...theme.applyStyles(MuiMode.DARK, {
              color: theme.palette.warning.main,
            }),
            "&:hover": {
              backgroundColor: alpha(theme.palette.warning.main, 0.25),
            },
          }),
        },
        {
          props: {
            color: "error" as ChipColor,
            variant: "pastel" as ChipVariant,
          },
          style: ({ theme }: { theme: Theme }) => ({
            "&:hover": {
              backgroundColor: alpha(theme.palette.error.main, 0.25),
            },
            backgroundColor: alpha(theme.palette.error.main, 0.1),
            color:
              theme.palette.error[
                theme.palette.mode === "light" ? "dark" : "light"
              ],
          }),
        },
        {
          props: {
            color: "ai" as ChipColor,
            variant: "pastel" as ChipVariant,
          },
          style: ({ theme }: { theme: Theme }) => ({
            "&:hover": {
              backgroundColor: alpha(theme.palette.ai.main, 0.25),
            },
            backgroundColor: alpha(theme.palette.ai.main, 0.1),
            color:
              theme.palette.ai[
                theme.palette.mode === "light" ? "dark" : "light"
              ],
          }),
        },
      ],
    },
    MuiCssBaseline: {
      styleOverrides: {
        ".print-only": {
          display: "none !important",
        },
        "@media print": {
          ".print-always, .print-only": {
            display: "flex !important",
          },
          "footer, nav, header, .no-print": {
            display: "none !important",
          },
          "html, body, body *": {
            colorAdjust: "exact",
            visibility: "hidden",
            webkitPrintColorAdjust: "exact",
          },
          main: {
            backgroundColor: `${WHITE} !important`,
            boxShadow: "none",
            color: `${BLACK} !important`,
            left: 0,
            margin: 0,
            padding: "0 !important",
            position: "absolute",
            top: 0,
          },
          "main, main *": {
            visibility: "visible",
          },
        },
        "*::selection": {
          backgroundColor: selectionColorByAppTheme[appTheme],
          color: paletteByAppTheme[appTheme].text.light,
        },
      },
    },
    MuiDialog: {
      styleOverrides: {
        root: ({ theme }: { theme: Theme }) => ({
          ".MuiBackdrop-root": {
            backgroundColor: paletteByAppTheme[appTheme].background.backdrop,
          },
          ".MuiDialogActions-root": {
            padding: `${theme.spacing(1.5)} ${theme.spacing(3)}`,
          },
          ".MuiDialogContent-root": {
            padding: `${theme.spacing(1.5)} ${theme.spacing(3)}`,
          },
        }),
      },
    },
    MuiMenu: {
      styleOverrides: {
        paper: ({ theme }: { theme: Theme }) => ({
          backdropFilter: "blur(32px) brightness(1.5)",
          backgroundColor: alpha(theme.palette.background.paper, 0.75),
          ...theme.applyStyles(MuiMode.DARK, {
            backdropFilter: "blur(32px) brightness(0.75)",
          }),
        }),
      },
    },
    MuiPaper: {
      styleOverrides: {
        root: {
          "@media print": {
            backgroundColor: `${WHITE} !important`,
            color: `${BLACK} !important`,
            height: "auto",
            overflow: "visible",
          },
          backgroundImage: "none",
          boxShadow: paletteByAppTheme[appTheme].shadow.default,
        },
      },
    },
    MuiTextField: {
      styleOverrides: {
        root: ({ theme }: { theme: Theme }) => ({
          "@media print": {
            display: "none",
          },
          fieldset: {
            transition: `border-color ${theme.transitions.duration.shorter}ms`,
          },
        }),
      },
    },
    MuiToggleButton: {
      styleOverrides: {
        root: {
          "@media print": {
            display: "none",
          },
          backgroundColor: paletteByAppTheme[appTheme].background.paper,
          borderColor: paletteByAppTheme[appTheme].border.dropZone,
        },
      },
    },
    MuiTypography: {
      defaultProps: {
        variantMapping: {
          hero: "h1",
        },
      },
    },
  },
  palette: paletteByAppTheme[appTheme],
  shape: {
    borderRadius: borderRadiusByAppTheme[appTheme],
  },
  typography: typographyByAppTheme[appTheme],
});

export const getMuiTheme = (appTheme: AppTheme) =>
  createTheme(getDesignTokens(appTheme));

export const useMuiTheme = () => {
  const { appTheme } = useAppTheme();

  const muiTheme = useMemo(
    () => getMuiTheme(appTheme ?? DEFAULT_APP_THEME),
    [appTheme],
  );

  return { muiTheme };
};
