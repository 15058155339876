import { ApolloClient, gql, Reference } from "@apollo/client";
import { Buffer } from "buffer";

import {
  IMAGE_FIELDS,
  LOCATION_FIELDS,
  VEHICLE_CARD_FIELDS,
} from "constants/fragments";
import { Location } from "typings/Location";
import { LocationService } from "typings/LocationService";
import { isDefaultOrganization } from "typings/Organization";

const FETCH_LOCATION = gql`
  ${LOCATION_FIELDS}
  ${VEHICLE_CARD_FIELDS}
  ${IMAGE_FIELDS}
  query FetchLocation($id: ID!) {
    location(id: $id) {
      ...LocationFields
      vehicles {
        ...VehicleCardFields
      }
      medias {
        ...ImageFields
      }
    }
  }
`;

const DELETE_LOCATION = gql`
  mutation DeleteLocation($id: ID!) {
    deleteLocation(id: $id) {
      void
    }
  }
`;

const ADD_LOCATION = gql`
  ${LOCATION_FIELDS}
  mutation AddLocation(
    $title: String!
    $description: String
    $type: String
    $walkThrough: String
    $address: String!
    $profilePictureId: ID
    $coverPictureId: ID
  ) {
    addLocation(
      locationData: {
        title: $title
        description: $description
        type: $type
        walkThrough: $walkThrough
        address: $address
        profilePictureId: $profilePictureId
        coverPictureId: $coverPictureId
      }
    ) {
      ...LocationFields
    }
  }
`;

const UPDATE_LOCATION = gql`
  ${LOCATION_FIELDS}
  mutation UpdateLocation(
    $id: ID!
    $title: String!
    $type: String
    $walkThrough: String
    $address: String!
    $description: String
    $profilePictureId: ID
    $coverPictureId: ID
  ) {
    updateLocation(
      locationData: {
        id: $id
        title: $title
        type: $type
        walkThrough: $walkThrough
        address: $address
        description: $description
        profilePictureId: $profilePictureId
        coverPictureId: $coverPictureId
      }
    ) {
      ...LocationFields
    }
  }
`;

export default class ApolloLocationService implements LocationService {
  constructor(private apolloClient: ApolloClient<any>) {}

  public async add(location: Partial<Location>): Promise<Location["id"]> {
    const { data, errors } = await this.apolloClient.mutate<{
      addLocation: Location;
    }>({
      mutation: ADD_LOCATION,
      variables: {
        ...location,
        address: Buffer.from(JSON.stringify(location.address)).toString(
          "base64",
        ),
        coverPictureId: location.coverPicture?.id,
        profilePictureId: location.profilePicture?.id,
      },
    });

    if (errors) {
      throw errors[0];
    }

    if (data) {
      if (!isDefaultOrganization(data.addLocation.organizationId)) {
        this.apolloClient.cache.modify<{ consignments: Reference[] }>({
          fields: {
            consignments: (existingLocations, { toReference }) => [
              toReference(data.addLocation) as Reference,
              ...existingLocations,
            ],
          },
        });
      } else {
        this.apolloClient.cache.modify<{ locations: Reference[] }>({
          fields: {
            locations: (existingLocations, { toReference }) => [
              toReference(data.addLocation) as Reference,
              ...existingLocations,
            ],
          },
        });
      }
    }

    return data?.addLocation?.id;
  }

  public async delete(id: Location["id"]): Promise<void> {
    const { errors } = await this.apolloClient.mutate<{
      deleteLocation: Location;
    }>({
      mutation: DELETE_LOCATION,
      variables: { id },
    });

    if (errors) {
      throw errors[0];
    }

    this.apolloClient.cache.evict({ id: `Location:${id}` });
  }

  public async fetch(id: Location["id"]): Promise<Location> {
    const { data, errors } = await this.apolloClient.query<{
      location: Location;
    }>({
      query: FETCH_LOCATION,
      variables: { id },
    });

    if (errors) {
      throw errors[0];
    }

    return data.location;
  }

  public async update(location: Partial<Location>): Promise<void> {
    const { errors } = await this.apolloClient.mutate({
      mutation: UPDATE_LOCATION,
      variables: {
        ...location,
        address: Buffer.from(JSON.stringify(location.address)).toString(
          "base64",
        ),
        coverPictureId: location.coverPicture?.id,
        profilePictureId: location.profilePicture?.id,
      },
    });

    if (errors) {
      throw errors[0];
    }
  }
}
