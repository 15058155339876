import { InMemoryCache } from "@apollo/client";

import { ColumnState } from "components/DataGrid/typings";
import { ActivityLog } from "typings/ActivityLog";
import { AppNotification } from "typings/AppNotification";
import { Collector } from "typings/Collector";
import { HistoryRecord } from "typings/HistoryRecord";
import { Location } from "typings/Location";
import { Media } from "typings/Media";
import { ServiceRequest } from "typings/ServiceRequest";
import { Vehicle } from "typings/Vehicle";
import { Wishlist } from "typings/Wishlist";

function tryParseJson<T>(response: unknown) {
  if (typeof response === "object") {
    return response as unknown as T;
  }
  if (typeof response === "string" && response !== "") {
    return JSON.parse(response) as T;
  }
  return null;
}

const parseListViewColumns = (columns: unknown[]): ColumnState[] =>
  columns.map((column) => {
    if (typeof column === "object") {
      return column as ColumnState;
    }

    let columnState: ColumnState;

    try {
      columnState = JSON.parse(column as string);
    } catch (_e) {
      columnState = { colId: column as string, hide: false };
    }
    return columnState;
  });

export const inMemoryCache = new InMemoryCache({
  possibleTypes: {
    CollectionFieldsInterfaceType: ["Collection", "Location"],
    GalleryFieldsInterfaceType: [
      "HistoryRecord",
      "Listing",
      "Location",
      "Vehicle",
      "Wishlist",
    ],
    UserFieldsInterfaceType: ["Admin", "Collector"],
  },
  typePolicies: {
    ActivityLog: {
      fields: {
        content(value: unknown) {
          return tryParseJson<ActivityLog["content"]>(value);
        },
      },
    },
    AppNotification: {
      fields: {
        payload(value: unknown) {
          return tryParseJson<AppNotification["payload"]>(value);
        },
      },
    },
    Collector: {
      fields: {
        dashboardLayouts(value: unknown) {
          return tryParseJson<Collector["dashboardLayouts"]>(value);
        },
        enabledExperimentalFeatures(value: unknown) {
          return tryParseJson<Collector["enabledExperimentalFeatures"]>(value);
        },
        marketing(value: unknown) {
          return tryParseJson<Collector["marketing"]>(value);
        },
        socialLinks(value: unknown) {
          return tryParseJson<Collector["socialLinks"]>(value);
        },
        vehicleDetailLayouts(value: unknown) {
          return tryParseJson<Collector["vehicleDetailLayouts"]>(value);
        },
      },
    },
    HistoryRecord: {
      fields: {
        address(value: unknown) {
          return tryParseJson<HistoryRecord["address"]>(value);
        },
      },
    },
    LayoutScope: {
      keyFields: ["layout"],
    },
    Listing: {
      fields: {
        specs(value: unknown) {
          return tryParseJson<Vehicle["specs"]>(value);
        },
      },
    },
    ListView: {
      fields: {
        columns(value: unknown[]) {
          return parseListViewColumns(value);
        },
      },
    },
    Location: {
      fields: {
        address(value: unknown) {
          return tryParseJson<Location["address"]>(value);
        },
      },
    },
    Media: {
      fields: {
        metadata(value: unknown) {
          return tryParseJson<Media["metadata"]>(value);
        },
      },
    },
    Negotiation: {
      fields: {
        specs(value: unknown) {
          return tryParseJson<Vehicle["specs"]>(value);
        },
      },
    },
    ServiceRequest: {
      fields: {
        details(value: unknown) {
          return tryParseJson<ServiceRequest["details"]>(value);
        },
      },
    },
    Vehicle: {
      fields: {
        specs(value: unknown) {
          return tryParseJson<Vehicle["specs"]>(value);
        },
        technicalData(value: unknown) {
          return tryParseJson<Vehicle["technicalData"]>(value);
        },
        vehicleData(value: unknown) {
          return tryParseJson<Vehicle["vehicleData"]>(value);
        },
      },
    },
    Wishlist: {
      fields: {
        specs(value: unknown) {
          return tryParseJson<Wishlist["specs"]>(value);
        },
        vehicleData(value: unknown) {
          return tryParseJson<Vehicle["vehicleData"]>(value);
        },
      },
    },
  },
});
