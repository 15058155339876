import { ApolloClient, gql } from "@apollo/client";

import { COLLECTOR_PROFILE_FIELDS } from "constants/fragments";
import { AdminCollectorService } from "typings/AdminCollectorService";
import { Collector } from "typings/Collector";

export const FETCH_COLLECTORS = gql`
  ${COLLECTOR_PROFILE_FIELDS}
  query FetchCollectors {
    collectors {
      ...CollectorProfileFields
      marketing
      tier {
        id
        title
      }
      tierExpirationDate
      externalId
    }
  }
`;

export const FETCH_COLLECTOR = gql`
  ${COLLECTOR_PROFILE_FIELDS}
  query FetchCollector($collectorId: ID!) {
    collector(id: $collectorId) {
      ...CollectorProfileFields
      id
      username
      profilePicture {
        id
        url
      }
      latestAccesses
      marketing
      tier {
        id
        title
      }
      tierExpirationDate
      externalId
    }
  }
`;

export default class ApolloAdminCollectorService
  implements AdminCollectorService
{
  constructor(private apolloClient: ApolloClient<any>) {}

  public async fetchCollector(collectorId: string): Promise<Collector> {
    const { data, error } = await this.apolloClient.query<{
      collector: Collector;
    }>({
      query: FETCH_COLLECTOR,
      variables: { collectorId },
    });

    if (error) {
      throw error;
    }

    return data.collector;
  }

  public async fetchCollectors(): Promise<Collector[]> {
    const { data, error } = await this.apolloClient.query<{
      collectors: Collector[];
    }>({
      query: FETCH_COLLECTORS,
    });

    if (error) {
      throw error;
    }

    return data.collectors;
  }
}
