import { useCallback, useEffect, useState } from "react";

export function useModalWithSubscription<T = unknown>(
  openTopic: string,
  closeTopics?: string | string[],
) {
  const [object, setObject] = useState<null | T>(null);

  const handleClose = useCallback(() => {
    setObject(null);
  }, [setObject]);

  useEffect(() => {
    const onOpen = PubSub.subscribe(openTopic, (_msg, data: T) => {
      setObject(data ?? ({} as T));
    });

    const onCloseTopics: string[] = [];

    if (closeTopics) {
      if (Array.isArray(closeTopics)) {
        closeTopics.forEach((topic) => {
          onCloseTopics.push(PubSub.subscribe(topic, handleClose));
        });
      } else {
        onCloseTopics.push(PubSub.subscribe(closeTopics, handleClose));
      }
    }

    return () => {
      PubSub.unsubscribe(onOpen);

      onCloseTopics.forEach((topic) => {
        PubSub.unsubscribe(topic);
      });
    };
  }, []);

  return {
    handleClose,
    object,
    open: !!object,
  };
}
